// src/pages/ExhibitionDashboard.js
import React, { useEffect } from 'react';
import { useParams, Link, Outlet } from 'react-router-dom'; 
import { useExhibition } from '../context/ExhibitionContext'; // Import the context

 
import ExhibitionSidebar from '../component/sidebar/ExhibitionSidebar';
import Topbar from '../component/utils/Topbar';

const ExhibitionDashboard = () => {
  const { exhibition_unique_id } = useParams(); // Get the exhibition ID from the URL
  const { setExhibitionId } = useExhibition(); // Access the setExhibitionId function from context

  // Update the exhibitionId in context when the component mounts or URL changes
  useEffect(() => {
    if (exhibition_unique_id) {
      setExhibitionId(exhibition_unique_id); // Set the exhibitionId in the context
    }
  }, [exhibition_unique_id, setExhibitionId]);

  return (
    <div>
        <ExhibitionSidebar/>

        <div className="content" style={{ flexGrow: 1, padding: "20px" }}>

          <Topbar/>
        <Outlet />

        
      </div>

      {/* <h2>Exhibition Dashboard</h2>
      <p>Exhibition ID: {exhibition_unique_id}</p>   */}

 
    </div>
  );
};

export default ExhibitionDashboard;
