import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate hook
import img5 from "../component/img/5.webp";
import { baseurl } from "../component/Baseurl"; // Import baseurl
import axios from "axios";

const Register = () => {
  const [formData, setFormData] = useState({
    org_name: "",
    org_email: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    org_name: "",
    org_email: "",
    general: ""
  });

  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate(); // Hook for navigation

  // Handle form input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrorMessages({ ...errorMessages, [id]: "" }); // Clear specific error when user starts typing
  };

  // Base64 encode the email
  const encodeEmail = (email) => {
    return btoa(email); // Encode email to Base64
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Log the formData to the console to check the data being sent
    console.log("Data being sent:", formData);

    // Set loading to true and simulate the registration process
    setLoading(true);

    try {
      // Make an API request to register the organization
      const response = await axios.post(`${baseurl}add-organizations`, formData);

      // Log the response from the API
      console.log("API response:", response);

      if (response.status === 201) {
        // Registration was successful, set success message
        setErrorMessages({ org_name: "", org_email: "", general: "Registration successful!" });
      
        // Encode email in Base64 and redirect to OTP page immediately
        const encodedEmail = encodeEmail(formData.org_email);
        navigate(`/verify-with-otp/${encodedEmail}`);
      }
      
    } catch (error) {
      console.error("There was an error!", error);

      // Set error messages based on response status code
      if (error.response) {
        const statusCode = error.response.status;

        switch (statusCode) {
          case 401:
            setErrorMessages({ ...errorMessages, general: "", org_name: "Organization already exists" });
            break;
          case 402:
            setErrorMessages({ ...errorMessages, general: "", org_name: "Organization already exists" });
            break;
          case 403:
            setErrorMessages({ ...errorMessages, general: "", org_email: "Email already exists" });
            break;
          case 500:
            setErrorMessages({ ...errorMessages, general: "Something went wrong. Please try again later." });
            break;
          case 400:
            setErrorMessages({ ...errorMessages, general: "Please try again after some time." });
            break;
          default:
            setErrorMessages({ ...errorMessages, general: "An unexpected error occurred. Please try again later." });
            break;
        }
      }
    } finally {
      setLoading(false); // Stop the loading spinner after 5 seconds or when the API response is received
    }
  };

  return (
    <section className="bg-signup d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={img5} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={img5} className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                  <img src={img5} className="d-block w-100" alt="..." />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="my-card p-5">

            <div>
                  <h3 className="header-title mb-4">HRNEXA</h3>
                </div>


              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3 className="header-title">Create an Account</h3>
                </div>
                <div>
                  <span className="header-subtitle">
                    Already have an account?{" "}
                    <Link  to="/">
                      Register
                    </Link>
                  </span>
                </div>
              </div>

              <div className="register mt-3">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="org_name">
                    <input
                      type="text"
                      id="org_name"
                      placeholder="Organization Name"
                      required
                      value={formData.org_name}
                      onChange={handleChange}
                    />
                    <span>Company Name</span>
                  </label>
                  {errorMessages.org_name && (
                    <div className="error-message errormsg">{errorMessages.org_name}</div>
                  )}

                  <label htmlFor="org_email">
                    <input
                      type="email"
                      id="org_email"
                      placeholder="Organization Email"
                      required
                      value={formData.org_email}
                      onChange={handleChange}
                    />
                    <span>Company Email</span>
                  </label>
                  {errorMessages.org_email && (
                    <div className="error-message errormsg">{errorMessages.org_email}</div>
                  )}

                  {errorMessages.general && (
                    <div className="error-message errormsg">{errorMessages.general}</div>
                  )}

                  <div className="text-center">
                    <button type="submit" className="btnn" disabled={loading}>
                      {loading ? (
                        <div>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Registering Organization...
                        </div>
                      ) : (
                        "Register"
                      )}
                    </button>
                  </div>
                </form>

                <hr className="mt-4 mb-4" />
                <p className="text-muted ptxt">
                  We’re committed to your privacy. Kredily uses the information
                  you provide to us to contact you about our relevant content,
                  products, and services. You may unsubscribe from these
                  communications at any time. For more information, check out
                  our <Link>Privacy Policy</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
