import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Camera, Home, Users, Grid, FilePlus, Lock, Calendar } from "react-feather";
import logo from '../img/logo.png';

const OrganisationSidebar = () => {
  const location = useLocation(); // Get the current location

  // Function to check if a route is active
  const isActive = (path) => location.pathname === path;

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <div className="">
          <img src={logo} className="img-fluid logo" alt="Evventoz Logo" />
        </div>
      </div>
      <ul className="sidebar-menu">
        <li className={isActive("/dashboard") ? "active" : ""}>
          <Link to="/dashboard" className="kp">
            <Home className="ic" />
            <span>Dashboard</span>
          </Link>
        </li>
        <li className={isActive("/all-event") ? "active" : ""}>
          <Link to="/all-event" className="kp">
            <Grid className="ic" />
            <span>All Event</span>
          </Link>
        </li>
        {/* <li className={isActive("/users") ? "active" : ""}>
          <Link to="/users" className="kp">
            <Grid className="ic" />
            <span>All Event</span>
          </Link>
        </li> */}
        <li className={isActive("/event-format") ? "active" : ""}>
          <Link to="/event-format" className="kp">
            <FilePlus className="ic" />
            <span>Create Event</span>
          </Link>
        </li>
        <li className={isActive("/user-access") ? "active" : ""}>
          <Link to="/user-access" className="kp">
            <Lock className="ic" />
            <span>User Access</span>
          </Link>
        </li>
        <li className={isActive("/my-event-calendar") ? "active" : ""}>
          <Link to="/my-event-calendar" className="kp">
            <Calendar className="ic" />
            <span>My Event Calendar</span>
          </Link>
        </li>
      </ul>

<div className="footer1 p-2">
  <div className="upgrade">Upgrade To Gold</div>
</div>

    </div>
  );
};

export default OrganisationSidebar;
