import React from "react";

const Contact = () => {
  return (
    <div className="text-start">
      <h1>Contact Page</h1>
      <p>This is the About Page. Learn more about us here.</p>
    </div>
  );
};

export default Contact;
