import React, { useState } from "react";
import { useExhibition } from "../../context/ExhibitionContext"; // Import the context hook
import { useNavigate } from 'react-router-dom'; // Import navigate hook
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2'; // Import SweetAlert2 for the confirmation dialog
import axios from 'axios'; // Import axios for API calls
import { baseurl } from "../../component/Baseurl";

const AddSponsor = () => {
  const { exhibitionId } = useExhibition(); // Assuming exhibitionId is available in context
  const navigate = useNavigate(); // For navigation after submission

  // State for form fields
  const [sponsorDetails, setSponsorDetails] = useState({
    sponsorName: "",
    title: "",
    website: "",
    profileImage: null, // To store the selected profile image
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSponsorDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Handle file input (Profile Image)
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSponsorDetails((prevDetails) => ({
      ...prevDetails,
      profileImage: file,
    }));
  };

  // Handle form submission with axios
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("sponsorName", sponsorDetails.sponsorName);
    formData.append("title", sponsorDetails.title);
    formData.append("website", sponsorDetails.website);
    if (sponsorDetails.profileImage) {
      formData.append("profileImage", sponsorDetails.profileImage);
    }
    formData.append("exhibitionId", exhibitionId);

    // Log the data to be sent
    console.log("FormData entries:");
    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    try {
      const response = await axios.post(`${baseurl}insertSponserDetails`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set proper header for form-data
        },
      });

      // Log the response from the API
      console.log("API Response:", response);

      if (response.data.success) {
        Swal.fire({
          title: "Success",
          text: "The sponsor has been added successfully.",
          icon: "success",
        });
        toast.success("Sponsor added successfully!");

        // Reset the form
        setSponsorDetails({
          sponsorName: "",
          title: "",
          website: "",
          profileImage: null,
        });
        window.location.reload();
      } else {
        toast.error(`Failed to add sponsor: ${response.data.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error("Error adding sponsor:", error);
      toast.error("Something went wrong. Please try again later.");
    }

    window.bootstrap.Modal.getInstance(document.getElementById('addSponsorModal')).hide();
  };

  // Preview profile image
  const renderImagePreview = () => {
    if (sponsorDetails.profileImage) {
      return (
        <div className="mt-3">
          <img
            src={URL.createObjectURL(sponsorDetails.profileImage)}
            alt="Profile Preview"
            className="img-fluid"
            style={{ maxWidth: '150px' }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <span><h1 className="mt-3">Sponsor List</h1></span>
        <button
          type="button"
          className="btnn"
          data-bs-toggle="modal"
          data-bs-target="#addSponsorModal"
        >
          Add Sponsor
        </button>
      </div>

      <div
        className="modal fade"
        id="addSponsorModal"
        tabIndex="-1"
        aria-labelledby="addSponsorModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addSponsorModalLabel">Add Sponsor</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="sponsorName">Sponsor Name<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="sponsorName"
                    className="form-control"
                    value={sponsorDetails.sponsorName}
                    onChange={handleInputChange}
                    placeholder="Enter Sponsor Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="title">Title<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    value={sponsorDetails.title}
                    onChange={handleInputChange}
                    placeholder="Enter Title"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="website">Website</label>
                  <input
                    type="url"
                    name="website"
                    className="form-control"
                    value={sponsorDetails.website}
                    onChange={handleInputChange}
                    placeholder="Enter Website URL"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="profileImage">Profile Image</label>
                  <input
                    type="file"
                    name="profileImage"
                    className="form-control"
                    onChange={handleFileChange}
                  />
                  {renderImagePreview()}
                </div>
                <div className="modal-footer">
                  {/* <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button> */}
                  <button type="submit" className="btnn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default AddSponsor;
