import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Camera, Home, LogOut as UsersIcon } from "react-feather"; 

const LogoutBtn = () => {
  return (
    <Link className="logoutbtn d-flex align-items-center" to="/logout">
            <UsersIcon className="ic" />
            <span>Logout</span>
          </Link>
  );
};

export default LogoutBtn;
