import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseurl } from "../../component/Baseurl"; 

const PageView = ({ exhibitionId }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [chartType, setChartType] = useState("line"); // State for chart type
  const [analyticsData, setAnalyticsData] = useState([]); // State for API data
  const [totalCount, setTotalCount] = useState(0); // State for total count

  // Fetch data from API
  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseurl}getIpAnalyticsCount/${exhibitionId}`);
      // console.log("response", response);
      setAnalyticsData(response.data.data);  
      const count = response.data.count;
      setTotalCount(count); // Set total count in state

      setIsLoaded(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [exhibitionId]);

  const renderChart = () => {
    if (isLoaded && window.JSC) {
      window.JSC.chart("chart-container", {
        type: chartType, // Use dynamic chart type
        title_font_size: 18,
        title_label_style: {
          fontWeight: "bold",
          color: "#333",
        },
        xAxis: {
          label_text: `Total Count ${totalCount}`, // Use the total count from state
          scale_type: "category",
          labels: {
            font_size: 12,
            font_color: "#666",
            font_family: "Arial, sans-serif",
          },
        },
        yAxis: {
          label_text: "Number of Visitors",
          scale_interval: 50,
          labels: {
            font_size: 12,
            font_color: "#666",
            font_family: "Arial, sans-serif",
          },
        },
        series: [
          {
            name: "Visitors",
            points: analyticsData.map((item) => ({
              x: item.lead_date, // assuming 'lead_date' is the date field
              y: Math.max(0, item.lead_count), // Ensure the y value is always positive
            })),
            line_width: 3,
            color: "#435ebe",
            marker: {
              visible: true,
              size: 5,
              color: "#435ebe",
              border: {
                width: 2,
                color: "#fff",
              },
            },
          },
        ],
        toolbar: {
          visible: false,
        },
        legend: {
          visible: false,
        },
      });
    }
  };

  useEffect(() => {
    renderChart();
  }, [isLoaded, chartType, analyticsData, totalCount]); // Add totalCount as dependency

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div style={{ marginBottom: "10px" }}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {" "}
                <div className="hd-1">Page Views</div>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="me-4">
                  <label htmlFor="chartType">Select Chart Type: </label>
                </div>
                <div className="me-3">
                  <select
                    id="chartType"
                    value={chartType}
                    className="form-control"
                    onChange={(e) => setChartType(e.target.value)}
                  >
                    <option value="line">Line Chart</option>
                    <option value="column">Bar Chart</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="chart-container"
        style={{
          height: "260px",
          width: "100%",
        }}
      ></div>
    </div>
  );
};

export default PageView;
