import React, { useState, useEffect } from "react";
import LogoutBtn from "../component/LogoutBtn";
import { baseurl, imageUrl } from "../component/Baseurl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import defaulrevent from "../component/img/defaultevent.avif";

const AllEvents = () => {
  const [events, setEvents] = useState([]); // Exhibitions
  const [conferences, setConferences] = useState([]); // Conferences
  const [loadingExhibitions, setLoadingExhibitions] = useState(true);
  const [loadingConferences, setLoadingConferences] = useState(true);
  const [errorExhibitions, setErrorExhibitions] = useState(null);
  const [errorConferences, setErrorConferences] = useState(null);

  const orgId = localStorage.getItem("orgId");  
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch exhibitions
    const fetchExhibitions = async () => {
      try {
        setLoadingExhibitions(true);
        const eventResponse = await axios.get(`${baseurl}AllExhibitions/${orgId}`);
        setEvents(eventResponse.data.exhibitions || []);
      } catch (err) {
        setErrorExhibitions(err.message || "Failed to fetch exhibitions");
      } finally {
        setLoadingExhibitions(false);
      }
    };

    // Fetch conferences
    const fetchConferences = async () => {
      try {
        setLoadingConferences(true);
        const conferenceResponse = await axios.get(`${baseurl}AllConferences/${orgId}`);
        setConferences(conferenceResponse.data.conferences || []);
      } catch (err) {
        // setErrorConferences(err.message || "Failed to fetch conferences");
      } finally {
        setLoadingConferences(false);
      }
    };

    fetchExhibitions();
    fetchConferences();
  }, [orgId]);

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  const handleEmptyState = () => {
    if (!loadingExhibitions && !loadingConferences && events.length === 0 && conferences.length === 0) {
      Swal.fire({
        icon: "info",
        title: "No Events Found",
        text: "Create Event or Conference Now",
        confirmButtonText: "Okay",
      }).then(() => {
        navigate("/event-format");
      });
    }
  };

  useEffect(() => {
    handleEmptyState();
  }, [loadingExhibitions, loadingConferences, events, conferences]);

  return (
    <div>
      <div className="d-flex tp-at align-items-center justify-content-between sticky-top">
        <div className="tp-h-tl">All Events</div>
        <div>
          <LogoutBtn />
        </div>
      </div>

      <div className="text-start">
        <h1 className="mt-3">All Events</h1>
      </div>

      <div className="container mt-4">
        {/* Exhibitions Section */}
        {loadingExhibitions ? (
          <p>Loading exhibitions...</p>
        ) : errorExhibitions ? (
          <p className="text-danger">{errorExhibitions}</p>
        ) : events.length > 0 ? (
          <div className="row">
            <div className="crd">
              <div className="hd-1">My Exhibitions</div>
              <hr />
              <div className="row g-2 mt-2">
                {events.map((event) => (
                  <div className="col-md-3" key={event.id}>
                    <Link to={`/exhibition-dashboard/${event.exhibition_unique_id}/stats`}>
                      <div className="imgdiv">
                        <img
                          src={
                            event.eventbanner
                              ? `${imageUrl}eventFiles/${event.eventbanner}`
                              : defaulrevent
                          }
                          alt={event.exhibition_name}
                          className="img-fluid alleventimg"
                        />
                      </div>
                    </Link>
                    <div className="p-2 py-0">
                      <div className="text-start">
                        <span className="spndate bg-blue">
                          {formatDate(event.exhibition_start_date)} - {formatDate(event.exhibition_end_date)}
                        </span>
                      </div>
                      <h4 className="mt-2 text-start">
                        <Link to={`/exhibition-dashboard/${event.exhibition_unique_id}/stats`}>
                          {event.exhibition_name}
                        </Link>
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <p className="text-center">No exhibitions available.</p>
        )}

        {/* Conferences Section */}
        {loadingConferences ? (
          <p>Loading conferences...</p>
        ) : errorConferences ? (
          <p className="text-danger">{errorConferences}</p>
        ) : conferences.length > 0 ? (
          <div className="row mt-4">
            <div className="crd">
              <div className="hd-1">My Conferences</div>
              <hr />
              <div className="row g-2 mt-2">
                {conferences.map((conference) => (
                  <div className="col-md-3" key={conference.id}>
                    <Link to={`/conference-dashboard/${conference.conference_unique_id}/conference-basic-details`}>
                      <div className="imgdiv">
                        <img
                          src={
                            conference.eventbanner
                              ? `${imageUrl}conferenceFiles/${conference.eventbanner}`
                              : defaulrevent
                          }
                          alt={conference.conference_name}
                          className="img-fluid alleventimg"
                        />
                      </div>
                    </Link>
                    <div className="p-2 py-0">
                      <div className="text-start">
                        <span className="spndate bg-blue">
                          {formatDate(conference.conference_start_date)} - {formatDate(conference.conference_end_date)}
                        </span>
                      </div>
                      <h4 className="mt-2 text-start">
                        <Link to={`/conference-dashboard/${conference.conference_unique_id}/conference-basic-details`}>
                          {conference.conference_name}
                        </Link>
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <p className="text-center"></p>
          // <p className="text-center">No conferences available.</p>
        )}
      </div>
    </div>
  );
};

export default AllEvents;
