import React, { useState, useEffect } from "react";
import { useExhibition } from "../../context/ExhibitionContext"; // Import the context hook
import axios from "axios"; // Import axios
import { baseurl } from "../../component/Baseurl"; // Base URL for API calls
import { useNavigate } from "react-router-dom"; // Import navigate hook
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar"; // Import Navbar
import Swal from 'sweetalert2'; 
import  { ArrowRight, ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";

const ExhibitionVenueDetails = () => {
  // Destructure the context to get the exhibition ID
  const { exhibitionId } = useExhibition();
  const navigate = useNavigate(); // For navigation after submission

  const org_id = localStorage.getItem("orgId");

  // State to track which toggle is active
  const [activeToggle, setActiveToggle] = useState(null);

  // State to hold form data including event_mode
  const [formData, setFormData] = useState({
    venueName: '',
    venueAddress: '',
    venueMap: '',
    timezone: '',
    joiningLink: '',
    eventMode: '' // Added event_mode to state
  });

  // Fetch venue details on mount
  useEffect(() => {
    const fetchVenueDetails = async () => {
      try {
        const response = await axios.get(`${baseurl}/Get_VenueDetails/${exhibitionId}`);
        const venueDetails = response.data.venueDetails[0]; // Assuming the API response structure

        if (venueDetails) {
          setFormData({
            venueName: venueDetails.venue_name,
            venueAddress: venueDetails.venue_address,
            venueMap: venueDetails.venue_map,
            timezone: venueDetails.timezone || '',
            joiningLink: venueDetails.joining_link || '',
            eventMode: venueDetails.event_mode || ''
          });

          // Set active toggle based on eventMode
          setActiveToggle(venueDetails.event_mode);
        }
      } catch (error) {
        // console.error("Error fetching venue details:", error);
        // toast.error("Error fetching venue details. Please try again.");
      }
    };

    if (exhibitionId) {
      fetchVenueDetails();
    }
  }, [exhibitionId]);

  // Handle toggle change
  const handleToggleChange = (toggle) => {
    setActiveToggle(activeToggle === toggle ? null : toggle); // Toggle on/off
    setFormData({ ...formData, eventMode: toggle }); // Update eventMode based on the toggle
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { venueName, venueAddress, venueMap, timezone, joiningLink, eventMode } = formData;
  
    // Validate form data (basic validation)
    if (!venueName || !venueAddress || !venueMap || (activeToggle === 'virtual' || activeToggle === 'hybrid') && (!timezone || !joiningLink)) {
      toast.error("Please fill in all required fields.");
      return;
    }
  
    try {
      // Prepare data to send to the backend
      const formDataToSend = {
        exhibitionId,
        orgId: org_id,
        venueName,
        venueAddress,
        venueMap,
        timezone,
        joiningLink,
        eventMode // Send eventMode in the request data
      };
  
      // Send POST request to the backend API
      const response = await axios.post(`${baseurl}/Insert_VenueDetails`, formDataToSend);
  
      if (response.status === 200) {
        // Show success message using console log or toast
        console.log('Venue details submitted successfully.');
        // toast.success('Venue details submitted successfully.');
  
        // Navigate to file_details after success
        navigate(`/exhibition-dashboard/${exhibitionId}/image-files`);
      } else {
        throw new Error("Submission failed");
      }
    } catch (error) {
      console.error("Error submitting exhibition details:", error);
      // toast.error("Error submitting exhibition details. Please try again later.");
    }
  };
  

  return (
    <div>
      <ExhibitionContentNavbar />
      <h1 className="mt-3">Exhibition Mode & Venue Details</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-3">
            <form onSubmit={handleSubmit}>
              <div className="row g-2">
                <div className="col-md-12">
                  <div className="form-check form-switch d-inline-block me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckInPerson"
                      checked={activeToggle === 'inPerson'}
                      onChange={() => handleToggleChange('inPerson')}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckInPerson">InPerson</label>
                  </div>

                  <div className="form-check form-switch d-inline-block me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckVirtual"
                      checked={activeToggle === 'virtual'}
                      onChange={() => handleToggleChange('virtual')}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckVirtual">Virtual</label>
                  </div>

                  <div className="form-check form-switch d-inline-block">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckHybrid"
                      checked={activeToggle === 'hybrid'}
                      onChange={() => handleToggleChange('hybrid')}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckHybrid">Hybrid</label>
                  </div>
                </div>

                {/* Conditionally render inputs based on active toggle */}
                {activeToggle === 'inPerson' || activeToggle === 'hybrid' ? (
                  <>
                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="venueName"
                        className="form-control"
                        placeholder="Enter Venue Name"
                        value={formData.venueName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Address <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Venue Address"
                        rows="3"
                        name="venueAddress"
                        value={formData.venueAddress}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Map <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Map Iframe Code"
                        rows="3"
                        name="venueMap"
                        value={formData.venueMap}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                  </>
                ) : null}

                {activeToggle === 'virtual' || activeToggle === 'hybrid' ? (
                  <>
                    <div className="col-md-12">
                      <label className="form-label">
                        Select TimeZone <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Timezone"
                        rows="3"
                        name="timezone"
                        value={formData.timezone}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Joining Link <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Joining Link"
                        rows="3"
                        name="joiningLink"
                        value={formData.joiningLink}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                  </>
                ) : null}

              </div>

              {/* <div className="col-md-12 mt-3 text-end">
                <button type="submit" className="btnn">
                  Submit
                </button>
              </div> */}


              {/* Submit Button */}
                            <div className="col-md-12 mt-5 d-flex justify-content-between align-items-center">
                              <span className="">
                                <Link to={`/exhibition-dashboard/${exhibitionId}/time-details`} className="btnn">
                                <ArrowLeft className="ic text-white" />
                                Back</Link>
                                </span>
                              <button type="submit" className="btnn mt-0">
                                Next <ArrowRight className="ic text-white" />
                              </button>
                            </div>


            </form>
          </div>
        </div>
      </div>

      <ToastContainer /> {/* Toast container for notifications */}
    </div>
  );
};

export default ExhibitionVenueDetails;
