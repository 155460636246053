import React, { useState, useEffect } from "react"; 
import { baseurl, imageUrl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { Edit, Trash2 } from "react-feather";  
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import { useExhibition } from "../../context/ExhibitionContext";
import Swal from 'sweetalert2'; // Import SweetAlert2
import AddSpeaker from "../ExhibitionComponent/AddSpeaker";
import  { ArrowRight, ArrowLeft } from "react-feather";


const ExhibitionSpeakerDetails = () => {
  const [speakers, setSpeakers] = useState([]); // State to store speaker details 
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [recordsPerPage, setRecordsPerPage] = useState(5); // State for records per page
  const { exhibitionId } = useExhibition(); 
  const navigate = useNavigate();

  // Fetch speaker details when the component mounts
  useEffect(() => {
    const fetchSpeakers = async () => {
      try {
        const response = await axios.get(`${baseurl}getSpeakers/${exhibitionId}`);
        console.log(response); // Log the full response object
        if (response.data.success) {
          setSpeakers(response.data.speakers); // Update state with fetched data
        } else {
          console.error("Failed to fetch speakers");
        }
      } catch (error) {
        // console.error("Error fetching speakers:", error);
      }
    };

    fetchSpeakers();
  }, [exhibitionId]);

  // Handle speaker deletion
  const handleDelete = (speakerId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          console.log("Sending delete request for speakerId:", speakerId); // Log the speakerId being sent
          const response = await axios.delete(`${baseurl}deleteSpeaker/${speakerId}`);
          
          console.log("Delete response:", response); // Log the response from the server
          
          if (response.data.success) {
            setSpeakers((prevSpeakers) =>
              prevSpeakers.filter((speaker) => speaker.id !== speakerId)
            );
            Swal.fire('Deleted!', 'The speaker has been deleted.', 'success');
          } else {
            Swal.fire('Error!', 'There was an issue deleting the speaker.', 'error');
          }
        } catch (error) {
          console.error('Error deleting speaker:', error);
          Swal.fire('Error!', 'There was an issue deleting the speaker.', 'error');
        }
      }
    });
  };

  // Handle change in records per page
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when records per page changes
  };

  // Calculate the current speakers to display
  const indexOfLastSpeaker = currentPage * recordsPerPage;
  const indexOfFirstSpeaker = indexOfLastSpeaker - recordsPerPage;
  const currentSpeakers = speakers.slice(indexOfFirstSpeaker, indexOfLastSpeaker);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(speakers.length / recordsPerPage);


  // Handle next button click
  const handleNextClick = () => {
    Swal.fire({
      title: 'Success!',
      text: 'Details submitted for review successfully.',
      icon: 'info',
      confirmButtonText: 'Go To Dashboard',
    }).then(() => {
      navigate(`/exhibition-dashboard/${exhibitionId}/stats`);
    });
  };



  return (
    <div>
      <ExhibitionContentNavbar />
      <AddSpeaker/>

      <div className="container mt-4">
        {/* Speaker List Section */}
        <div className="row">
          <div className="crd">
            <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="hd-1">Speaker List</div>

            <div>
              <label>Records Per Page:</label>
              <select
                className="form-control"
                value={recordsPerPage}
                onChange={handleRecordsPerPageChange}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={500}>500</option>
              </select>
            </div>
            </div>
            
            <hr />

            {/* Table Headers */}
            <div className="row text-start tblrowhd">
              <div className="col">Image</div>
              <div className="col">Name</div>
              <div className="col">Designation</div>
              <div className="col">Company</div>
              <div className="col">Email</div>
              <div className="col">Phone</div>
              <div className="col text-end">Action</div>
            </div>
            <hr />

            {/* Table Data */}
            {currentSpeakers.length > 0 ? (
              currentSpeakers.map((speaker) => (
                <div key={speaker.id} className="row user-table-data tblrowtd text-start">
                  <div className="col">
                    {speaker.profile_image ? (
                      <img src={`${imageUrl}/speakerFiles/${speaker.profile_image}`} alt={speaker.name} width="50" />
                    ) : (
                      <span>No Image</span>
                    )}
                  </div>
                  <div className="col">{speaker.name}</div>
                  <div className="col">{speaker.designation}</div>
                  <div className="col">{speaker.company}</div>
                  <div className="col">{speaker.email}</div>
                  <div className="col">{speaker.phone || 'N/A'}</div>
                  <div className="col text-end">
                    <Link to={`/exhibition-dashboard/${exhibitionId}/edit-speaker`} className="editbg">
                      <Edit className="ic1" />
                    </Link>

                    <span onClick={() => handleDelete(speaker.id)}>
                      <Trash2 className="ic1" />
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="row">
                <div className="col text-center">No speakers found.</div>
              </div>
            )}

            {/* Pagination and Records Per Page */}
            <div className="row">
              <div className="col d-flex justify-content-between align-items-center">
                {/* Pagination Controls */}
                <div>
                  <button 
                    className="btn btn-light" 
                    onClick={() => paginate(currentPage - 1)} 
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span className="mx-2">{currentPage}</span>
                  <button 
                    className="btn btn-light" 
                    onClick={() => paginate(currentPage + 1)} 
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>


            <div className="col-md-12 mt-5 mb-3 d-flex justify-content-between align-items-center">
                                                  <span className="">
                                                    <Link
                                                      to={`/exhibition-dashboard/${exhibitionId}/partner-details`}
                                                      className="btnn mybtn"
                                                    >
                                                      <ArrowLeft className="ic text-white" />
                                                      Back
                                                    </Link>
                                                  </span>
                                                  <span className="" onClick={handleNextClick}>
                                                    <Link 
                                                      className="btnn mybtn"
                                                    >
                                                      Submit <ArrowRight className="ic text-white" />
                                                    </Link>
                                                  </span>
                                                </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionSpeakerDetails;
