import React, { useState, useEffect } from "react"; 
import { baseurl, imageUrl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link } from 'react-router-dom'; 
import { Edit, Trash2 } from "react-feather";  
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import { useExhibition } from "../../context/ExhibitionContext";
import Swal from 'sweetalert2'; // Import SweetAlert2 
import AddSponser from "../ExhibitionComponent/AddSponser";
import { ArrowRight, ArrowLeft } from "react-feather";

const ExhibitionSponserDetails = () => {
  const [sponsors, setSponsors] = useState([]); // State to store sponsor details 
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [recordsPerPage, setRecordsPerPage] = useState(5); // State for records per page
  const { exhibitionId } = useExhibition(); 

  // Fetch sponsor details when the component mounts
  useEffect(() => {
    const fetchSponsors = async () => {
      try {
        const response = await axios.get(`${baseurl}getSponsers/${exhibitionId}`);
        console.log(response); // Log the full response object
        if (response.data.success) {
          setSponsors(response.data.sponsors); // Update state with fetched data
        } else {
          console.error("Failed to fetch sponsors");
        }
      } catch (error) {
        // console.error("Error fetching sponsors:", error);
      }
    };

    fetchSponsors();
  }, [exhibitionId]);

  // Handle sponsor deletion
  const handleDelete = (sponsorId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          console.log("Sending delete request for sponsorId:", sponsorId); // Log the sponsorId being sent
          const response = await axios.delete(`${baseurl}deleteSponser/${sponsorId}`);
          
          console.log("Delete response:", response); // Log the response from the server
          
          if (response.data.success) {
            setSponsors((prevSponsors) =>
              prevSponsors.filter((sponsor) => sponsor.id !== sponsorId)
            );
            Swal.fire('Deleted!', 'The sponsor has been deleted.', 'success');
          } else {
            Swal.fire('Error!', 'There was an issue deleting the sponsor.', 'error');
          }
        } catch (error) {
          console.error('Error deleting sponsor:', error);
          Swal.fire('Error!', 'There was an issue deleting the sponsor.', 'error');
        }
      }
    });
  };

  // Handle change in records per page
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when records per page changes
  };

  // Calculate the current sponsors to display
  const indexOfLastSponsor = currentPage * recordsPerPage;
  const indexOfFirstSponsor = indexOfLastSponsor - recordsPerPage;
  const currentSponsors = sponsors.slice(indexOfFirstSponsor, indexOfLastSponsor);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(sponsors.length / recordsPerPage);

  return (
    <div>
      <ExhibitionContentNavbar />
      <AddSponser />

      <div className="container mt-4">
        {/* Sponsor List Section */}
        <div className="row">
          <div className="crd">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="hd-1">Sponsor List</div>

              <div>
                <label>Records Per Page:</label>
                <select
                  className="form-control"
                  value={recordsPerPage}
                  onChange={handleRecordsPerPageChange}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                </select>
              </div>
            </div>
            
            <hr />

            {/* Table Headers */}
            <div className="row text-start tblrowhd">
              <div className="col">Image</div>
              <div className="col">Name</div>
              <div className="col">Title</div>
              <div className="col">Website</div> 
              <div className="col text-end">Action</div>
            </div>
            <hr />

            {/* Table Data */}
            {currentSponsors.length > 0 ? (
              currentSponsors.map((sponsor) => (
                <div key={sponsor.id} className="row user-table-data tblrowtd text-start">
                  <div className="col">
                    {sponsor.profile_image ? (
                      <img src={`${imageUrl}/sponsorFiles/${sponsor.profile_image}`} alt={sponsor.name} width="50" />
                    ) : (
                      <span>No Image</span>
                    )}
                  </div>
                  <div className="col">{sponsor.name}</div>
                  <div className="col">{sponsor.title}</div>
                  <div className="col">
                    <a href={sponsor.website} target="_blank" rel="noopener noreferrer">
                      {sponsor.website}
                    </a>
                  </div> 
                  <div className="col text-end">
                    <Link to={`/exhibition-dashboard/${exhibitionId}/edit-sponsor`} className="editbg">
                      <Edit className="ic1" />
                    </Link>

                    <span onClick={() => handleDelete(sponsor.id)}>
                      <Trash2 className="ic1" />
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="row">
                <div className="col text-center">No sponsors found.</div>
              </div>
            )}

            {/* Pagination and Records Per Page */}
            <div className="row">
              <div className="col d-flex justify-content-between align-items-center">
                {/* Pagination Controls */}
                <div className="d-flex justify-content-between align-items-baseline">
                  <button 
                    className="btn btn-light" 
                    onClick={() => paginate(currentPage - 1)} 
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span className="mx-2">{currentPage}</span>
                  <button 
                    className="btn btn-light" 
                    onClick={() => paginate(currentPage + 1)} 
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-5 mb-3 d-flex justify-content-between align-items-center">
                          <span className="">
                            <Link
                              to={`/exhibition-dashboard/${exhibitionId}/exhibitor-details`}
                              className="btnn mybtn"
                            >
                              <ArrowLeft className="ic text-white" />
                              Back
                            </Link>
                          </span>
                          <span className="">
                            <Link
                              to={`/exhibition-dashboard/${exhibitionId}/partner-details`}
                              className="btnn mybtn"
                            >
                              Next <ArrowRight className="ic text-white" />
                            </Link>
                          </span>
                        </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionSponserDetails;
