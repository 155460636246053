import React, { useState, useEffect } from "react";
import { baseurl, imageUrl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link } from "react-router-dom";
import { Edit, Trash2 } from "react-feather";
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import AddExhibitor from "../ExhibitionComponent/AddExhibitor";
import { useExhibition } from "../../context/ExhibitionContext";
import Swal from "sweetalert2"; // Import SweetAlert2
import { ArrowRight, ArrowLeft } from "react-feather";

const ExhibitionExhibitorDetails = () => {
  const [exhibitors, setExhibitors] = useState([]); // State to store exhibitor details
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [recordsPerPage, setRecordsPerPage] = useState(5); // State for records per page
  const { exhibitionId } = useExhibition();

  // Fetch exhibitor details when the component mounts
  useEffect(() => {
    const fetchExhibitors = async () => {
      try {
        const response = await axios.get(
          `${baseurl}getExhibitors/${exhibitionId}`
        );
        console.log(response); // Log the full response object
        if (response.data.success) {
          setExhibitors(response.data.exhibitors); // Update state with fetched data
        } else {
          console.error("Failed to fetch exhibitors");
        }
      } catch (error) {
        // console.error("Error fetching exhibitors:", error);
      }
    };

    fetchExhibitors();
  }, [exhibitionId]);

  // Handle exhibitor deletion
  const handleDelete = (exhibitorId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          console.log("Sending delete request for exhibitorId:", exhibitorId); // Log the exhibitorId being sent
          const response = await axios.delete(
            `${baseurl}deleteExhibitor/${exhibitorId}`
          );

          console.log("Delete response:", response); // Log the response from the server

          if (response.data.success) {
            setExhibitors((prevExhibitors) =>
              prevExhibitors.filter((exhibitor) => exhibitor.id !== exhibitorId)
            );
            Swal.fire("Deleted!", "The exhibitor has been deleted.", "success");
          } else {
            Swal.fire(
              "Error!",
              "There was an issue deleting the exhibitor.",
              "error"
            );
          }
        } catch (error) {
          console.error("Error deleting exhibitor:", error);
          Swal.fire(
            "Error!",
            "There was an issue deleting the exhibitor.",
            "error"
          );
        }
      }
    });
  };

  // Handle change in records per page
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when records per page changes
  };

  // Calculate the current exhibitors to display
  const indexOfLastExhibitor = currentPage * recordsPerPage;
  const indexOfFirstExhibitor = indexOfLastExhibitor - recordsPerPage;
  const currentExhibitors = exhibitors.slice(
    indexOfFirstExhibitor,
    indexOfLastExhibitor
  );

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(exhibitors.length / recordsPerPage);

  return (
    <div>
      <ExhibitionContentNavbar />
      <AddExhibitor />

      <div className="container mt-4">
        {/* Exhibitor List Section */}
        <div className="row">
          <div className="crd">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="hd-1">Exhibitor List</div>

              <div>
                <label>Records Per Page:</label>
                <select
                  className="form-control"
                  value={recordsPerPage}
                  onChange={handleRecordsPerPageChange}
                >
                  {/* <option value={5}>5 per page</option> */}
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                </select>
              </div>
            </div>

            <hr />

            {/* Table Headers */}
            <div className="row text-start tblrowhd">
              <div className="col">Image</div>
              <div className="col">Name</div>
              <div className="col">Designation</div>
              <div className="col">Company</div>
              <div className="col">Email</div>
              <div className="col">Phone</div>
              <div className="col">Booth No</div>
              <div className="col text-end">Action</div>
            </div>
            <hr />

            {/* Table Data */}
            {currentExhibitors.length > 0 ? (
              currentExhibitors.map((exhibitor) => (
                <div
                  key={exhibitor.id}
                  className="row user-table-data tblrowtd text-start"
                >
                  <div className="col">
                    {exhibitor.profile_image ? (
                      <img
                        src={`${imageUrl}/exhibitorFiles/${exhibitor.profile_image}`}
                        alt={exhibitor.name}
                        width="50"
                      />
                    ) : (
                      <span>No Image</span>
                    )}
                  </div>
                  <div className="col">{exhibitor.name}</div>
                  <div className="col">{exhibitor.designation}</div>
                  <div className="col">{exhibitor.company}</div>
                  <div className="col">{exhibitor.email}</div>
                  <div className="col">{exhibitor.phone || "N/A"}</div>
                  <div className="col">{exhibitor.booth_no || "N/A"}</div>
                  <div className="col text-end">
                    <Link
                      to={`/exhibition-dashboard/${exhibitionId}/edit-exhibitor`}
                      className="editbg"
                    >
                      <Edit className="ic1" />
                    </Link>

                    <span onClick={() => handleDelete(exhibitor.id)}>
                      <Trash2 className="ic1" />
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="row">
                <div className="col text-center">No exhibitors found.</div>
              </div>
            )}

            {/* Pagination and Records Per Page */}
            <div className="row">
              <div className="col d-flex justify-content-between align-items-center">
                {/* Dropdown to select records per page */}

                {/* Pagination Controls */}
                <div>
                  <button
                    className="btn btn-light"
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span className="mx-2">{currentPage}</span>
                  <button
                    className="btn btn-light"
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-5 mb-5 d-flex justify-content-between align-items-center">
              <span className="">
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/image-files`}
                  className="btnn mybtn"
                >
                  <ArrowLeft className="ic text-white" />
                  Back
                </Link>
              </span>
              <span className="">
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/sponser-details`}
                  className="btnn mybtn"
                >
                  Next <ArrowRight className="ic text-white" />
                </Link>
              </span>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionExhibitorDetails;
