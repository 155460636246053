import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseurl } from "../../component/Baseurl";

const LeadGenerated = ({ exhibitionId }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [chartData, setChartData] = useState([]); // State to store fetched chart data
  const [chartType, setChartType] = useState("line"); // State to manage chart type

  // Fetch data from the API
  const fetchChartData = async () => {
    try {
      const response = await axios.get(
        `${baseurl}fetchFormSubmitCount/${exhibitionId}`
      );

      if (response.data.success) {
        const formattedData = response.data.date_wise_counts.map((item) => ({
          x: item.date, // e.g., "12 Jan"
          y: item.count, // Number of leads
        }));

        setChartData(formattedData);
      } else {
        console.error("Failed to fetch data:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching chart data:", error.message);
    }
  };

  // Load JSC script dynamically
  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = "https://code.jscharting.com/latest/jscharting.js";
      script.onload = () => setIsLoaded(true);
      script.onerror = () => console.error("Failed to load JSC script");
      document.head.appendChild(script);
    };

    if (!window.JSC) {
      loadScript();
    } else {
      setIsLoaded(true);
    }
  }, []);

  // Fetch data when the component mounts or exhibitionId changes
  useEffect(() => {
    if (exhibitionId) {
      fetchChartData();
    }
  }, [exhibitionId]);

  // Render chart when script is loaded and data is available
  useEffect(() => {
    if (isLoaded && window.JSC && chartData.length > 0) {
      window.JSC.chart("line-chart-container2", {
        type: chartType, // Dynamic chart type (line or column)
        title_font_size: 18,
        title_label_style: {
          fontWeight: "bold",
          color: "#333",
        },
        xAxis: {
          label_text: "Days",
          scale_type: "category",
          labels: {
            font_size: 12,
            font_family: "Arial, sans-serif",
            font_color: "#666",
          },
        },
        yAxis: {
          label_text: "Number of Leads",
          scale_interval: 50,
          labels: {
            font_size: 12,
            font_color: "#666",
            font_family: "Arial, sans-serif",
          },
        },
        series: [
          {
            name: "Leads",
            points: chartData,
            color: "#435ebe",
            lineWidth: 3,
            marker: {
              visible: true,
              size: 6,
              color: "#435ebe",
            },
          },
        ],
        toolbar: {
          visible: false,
        },
        legend: {
          visible: false,
        },
      });
    }
  }, [isLoaded, chartData, chartType]); // Add chartType to re-render chart when it changes

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div style={{ marginBottom: "10px" }}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="hd-1">Leads Generated</div>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="me-4">
                  <label htmlFor="chartType">Select Chart Type: </label>
                </div>
                <div className="me-3">
                  <select
                    id="chartType"
                    value={chartType}
                    className="form-control"
                    onChange={(e) => setChartType(e.target.value)}
                  >
                    <option value="line">Line Chart</option>
                    <option value="column">Bar Chart</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="line-chart-container2"
        style={{
          height: "300px",
          width: "100%",
        }}
      ></div>
    </>
  );
};

export default LeadGenerated;
