import React, { useState, useEffect } from "react";
import { baseurl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link } from "react-router-dom";
import { Edit, Trash2, CornerUpRight, Star } from "react-feather";
import { useExhibition } from "../../context/ExhibitionContext";
import defaultprofile from "../img/profile.webp";
import ExhibitionCommunicationNavbar from "../utils/ExhibitionCommunicationNavbar";

const ExhibitionFollowers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [showReplyBox, setShowReplyBox] = useState(false); // State for reply box visibility

  const { exhibitionId } = useExhibition();

  // Sample rating data
  const ratingsData = {
    5: 50, // 50% of ratings are 5
    4: 25,
    3: 15,
    2: 7,
    1: 3,
  };

  // Decoded labels for keys
  const ratingLabels = {
    5: "Excellent",
    4: "Very Good",
    3: "Good",
    2: "Average",
    1: "Poor",
  };

  // Function to toggle reply box
  const ShowReplyBox = () => {
    setShowReplyBox((prev) => !prev);
  };

  return (
    <div>
      <ExhibitionCommunicationNavbar />
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h1 className="mt-3">Event Followers</h1>
          </div>
        </div>

        <div className="row">
          <div className="crd py-4">
            <div className="row g-2">
              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>

              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>

              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>

              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>

              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>

              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>

              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>

              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>

              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>

              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>


              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>


              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>


              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>


              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>


              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>


              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>


              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>


              <div className="col-2 ">
                <div className="border rounded text-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="img name here"
                    className="img-fluid mx100"
                  />
                </div>
                <div className="nm1">Ahtesham Ali</div>
                <div className="nm1 text-muted">User Position Here</div>
                <div className="nm1 text-muted">India</div>
                </div>
                
              </div>

              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionFollowers;
