import React, { useState, useEffect } from "react";
import { useExhibition } from "../../context/ExhibitionContext";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { baseurl } from "../../component/Baseurl";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import { Link, useLocation } from "react-router-dom";
import { PlusSquare, Trash2, Edit, Feather } from "react-feather";


const ExhibitionConferenceDetails = () => {
  const { exhibitionId } = useExhibition();
  const navigate = useNavigate();
  const org_id = localStorage.getItem("orgId");
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  const [formData, setFormData] = useState({
    conferenceName: "",
    conferenceDescription: "",
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [conferenceList, setConferenceList] = useState([]);
  const [editConferenceData, setEditConferenceData] = useState(null); // State to handle editing conference data

  useEffect(() => {
    const fetchConferenceDetails = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/getConferenceByExhibitionId/${exhibitionId}`
        );
        if (response.status === 200 && response.data.data && response.data.data.length > 0) {
          setConferenceList(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching conference details:", error);
      }
    };

    fetchConferenceDetails();
  }, [exhibitionId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      toast.error("End date must be after the start date.");
      setEndDate(null);
    } else {
      setEndDate(date);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.conferenceName || !formData.conferenceDescription || !startDate || !endDate) {
      toast.error("All fields are required!");
      return;
    }

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to submit the conference details?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, Submit!",
      cancelButtonText: "No, Cancel!",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.post(`${baseurl}/create-exhibition-conference`, {
          org_id,
          exhibitionId,
          ...formData,
          startDate,
          endDate,
        });

        if (response.status === 201) {
          Swal.fire("Submitted!", "Conference details submitted successfully!", "success");
          window.location.reload();
        }
      } catch (error) {
        console.error("API Error:", error);
        Swal.fire("Error", "Error submitting conference details!", "error");
      }
    }
  };

  // Open edit modal and set data
  const handleEditClick = (conference) => {
    setEditConferenceData(conference); // Set conference data in state for editing
    setFormData({
      conferenceName: conference.conference_name,
      conferenceDescription: conference.conference_description,
    });
    setStartDate(new Date(conference.conference_start_date));
    setEndDate(new Date(conference.conference_end_date));
  };

  // Handle update on edit modal submit
const handleUpdate = async (e) => {
    e.preventDefault();
  
    if (!formData.conferenceName || !formData.conferenceDescription || !startDate || !endDate) {
      toast.error("All fields are required!");
      return;
    }
  
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to update the conference details?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, Update!",
      cancelButtonText: "No, Cancel!",
    });
  
    if (result.isConfirmed) {
      try {
        // Log the data being sent to the API
        const dataToSend = {
          org_id,
          exhibitionId,
          ...formData,
          startDate,
          endDate,
        };
  
        console.log("Data being sent to API:", dataToSend); // Log the data
  
        const response = await axios.post(`${baseurl}update-exhibition-conference/${editConferenceData.id}`, dataToSend);
  
        if (response.status === 200) {
          Swal.fire("Updated!", "Conference details updated successfully!", "success");
          window.location.reload();
        }
      } catch (error) {
        console.error("API Error:", error);
        Swal.fire("Error", "Error updating conference details!", "error");
      }
    }
  };
  

  return (
    <div>
      <ExhibitionContentNavbar />
      <h1 className="mt-3">Conference Details</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-3">
            <div className="d-flex chaintop mb-3">
              <span>
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/conference-details`}
                  className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/conference-details`) ? "active1" : ""}`}
                >
                  Partner Custom Form
                </Link>
              </span>

              <span>
                <Link
                  to={`/exhibition-dashboard/${exhibitionId}/exhibition-conference-time`}
                  className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/exhibition-conference-time`) ? "active1" : ""}`}
                >
                  Partner Custom Form
                </Link>
              </span>
            </div>

            {/* Button to trigger the modal */}
            <div className="text-end mb-2">
              <button className="btnn mt-0" data-bs-toggle="modal" data-bs-target="#conferenceModal">
                Add Conference
              </button>
            </div>

            {/* Add Conference Modal */}
            <div className="modal fade" id="conferenceModal" tabIndex="-1" aria-labelledby="conferenceModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="conferenceModalLabel">Add Conference</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="conferenceName" className="form-label">
                          Conference Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="conferenceName"
                          className="form-control"
                          id="conferenceName"
                          placeholder="Enter Conference Name"
                          value={formData.conferenceName}
                          onChange={handleInputChange}
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="conferenceDescription" className="form-label">
                          Conference Description <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          id="conferenceDescription"
                          placeholder="Enter Conference Description"
                          rows="6"
                          name="conferenceDescription"
                          value={formData.conferenceDescription}
                          onChange={handleInputChange}
                          maxLength="1000"
                          required
                        ></textarea>
                        <div className="text-end">
                          <small>{formData.conferenceDescription.length} / 1000</small>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="startDate" className="form-label">
                          Conference Start Date <span className="text-danger">*</span>
                        </label>
                        <ReactDatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          placeholderText="Select Start Date"
                          required
                          minDate={new Date()}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="endDate" className="form-label">
                          Conference End Date <span className="text-danger">*</span>
                        </label>
                        <ReactDatePicker
                          selected={endDate}
                          onChange={handleEndDateChange}
                          placeholderText="Select End Date"
                          required
                          minDate={startDate || new Date()}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                        />
                      </div>

                      <div className="text-end">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* Edit Conference Modal */}
            <div className="modal fade" id="editConferenceModal" tabIndex="-1" aria-labelledby="editConferenceModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="editConferenceModalLabel">Edit Conference</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={handleUpdate}>
                      <div className="mb-3">
                        <label htmlFor="conferenceName" className="form-label">
                          Conference Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="conferenceName"
                          className="form-control"
                          id="conferenceName"
                          placeholder="Enter Conference Name"
                          value={formData.conferenceName}
                          onChange={handleInputChange}
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="conferenceDescription" className="form-label">
                          Conference Description <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          id="conferenceDescription"
                          placeholder="Enter Conference Description"
                          rows="6"
                          name="conferenceDescription"
                          value={formData.conferenceDescription}
                          onChange={handleInputChange}
                          maxLength="1000"
                          required
                        ></textarea>
                        <div className="text-end">
                          <small>{formData.conferenceDescription.length} / 1000</small>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label htmlFor="startDate" className="form-label">
                          Conference Start Date <span className="text-danger">*</span>
                        </label>
                        <ReactDatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          placeholderText="Select Start Date"
                          required
                          minDate={new Date()}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="endDate" className="form-label">
                          Conference End Date <span className="text-danger">*</span>
                        </label>
                        <ReactDatePicker
                          selected={endDate}
                          onChange={handleEndDateChange}
                          placeholderText="Select End Date"
                          required
                          minDate={startDate || new Date()}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                        />
                      </div>

                      <div className="text-end">
                        <button type="submit" className="btn btn-primary">
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* List of Conferences */}
           
                {conferenceList.map((conference) => (
                    <div key={conference.id} className="border rounded p-2 mb-3">
                    <div className="hd-2 text-capitalize mb-0">
                        <strong>{conference.conference_name}</strong> {" "} (
                        <span>{new Date(conference.conference_start_date).toLocaleDateString()}</span> {" "}-{" "}  
                        <span>{new Date(conference.conference_end_date).toLocaleDateString()}</span>)
                        </div>
                    <hr className="mb-2"/>
                    <div className="mb-2" style={{textAlign: "justify"}}>{conference.conference_description}</div> 
                    <hr className="mb-2"/>
                    <div className="text-end">
                    <span className="editvari text-white me-2" onClick={() => handleEditClick(conference)} data-bs-toggle="modal" data-bs-target="#editConferenceModal">
                      <Feather className="ic text-white" style={{cursor: "pointer"}} />Session/ Theame
                      </span>
 
                      <span className="editvari text-white me-2" onClick={() => handleEditClick(conference)} data-bs-toggle="modal" data-bs-target="#editConferenceModal">
                      <Edit className="ic text-white" style={{cursor: "pointer"}} />   Edit Details
                      </span>
                    </div>
                    
                </div>

                 
                ))} 

            
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ExhibitionConferenceDetails;
