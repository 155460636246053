import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Camera, Home, LogOut as UsersIcon } from "react-feather"; 

const GoToDash = () => {
  return (
    <Link className="logoutbtn d-flex align-items-center" to="/dashboard">
            <UsersIcon className="ic" />
            <span>Go To Dashboard</span>
          </Link>
  );
};

export default GoToDash;
