import React from "react";
import { FaHome, FaInfoCircle, FaUser } from "react-icons/fa"; // Optional icons
 
import OrganisationSidebar from "./sidebar/OrganisationSidebar";

const Sidebar = () => {
  return (
    <OrganisationSidebar/>
  );
};

export default Sidebar;
