// src/components/ExhibitionComp/DashboardComp.js
import React, { useEffect } from "react";
import { useExhibition } from "../../context/ExhibitionContext";  // Import the context

const DashboardComp = () => {
  const { exhibitionId } = useExhibition();  // Access the exhibitionId from the context

  return (
    <div>
      <h2>Dashboard Component</h2>  
      <p>Exhibition ID from Context: {exhibitionId}</p>
    </div>
  );
};

export default DashboardComp;
