import React, { useState, useEffect } from "react"; 
import { baseurl, imageUrl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link } from 'react-router-dom'; 
import { Edit, Trash2 } from "react-feather";  
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import { useExhibition } from "../../context/ExhibitionContext";
import Swal from 'sweetalert2'; // Import SweetAlert2 
import AddPartner from "../ExhibitionComponent/AddPartner";
import  { ArrowRight, ArrowLeft } from "react-feather";


const ExhibitionPartnerDetails = () => {
  const [partners, setPartners] = useState([]); // State to store partner details 
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [recordsPerPage, setRecordsPerPage] = useState(5); // State for records per page
  const { exhibitionId } = useExhibition(); 

  // Fetch partner details when the component mounts
  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get(`${baseurl}getPartners/${exhibitionId}`);
        // console.log(response); 
        if (response.data.success) {
          setPartners(response.data.partners); // Update state with fetched data
        } else {
          console.error("Failed to fetch partners");
        }
      } catch (error) {
        // console.error("Error fetching partners:", error);
      }
    };

    fetchPartners();
  }, [exhibitionId]);

  // Handle partner deletion
  const handleDelete = (partnerId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          console.log("Sending delete request for partnerId:", partnerId); // Log the partnerId being sent
          const response = await axios.delete(`${baseurl}deletePartner/${partnerId}`);
          
          console.log("Delete response:", response); // Log the response from the server
          
          if (response.data.success) {
            setPartners((prevPartners) =>
              prevPartners.filter((partner) => partner.id !== partnerId)
            );
            Swal.fire('Deleted!', 'The partner has been deleted.', 'success');
          } else {
            Swal.fire('Error!', 'There was an issue deleting the partner.', 'error');
          }
        } catch (error) {
          console.error('Error deleting partner:', error);
          Swal.fire('Error!', 'There was an issue deleting the partner.', 'error');
        }
      }
    });
  };

  // Handle change in records per page
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when records per page changes
  };

  // Calculate the current partners to display
  const indexOfLastPartner = currentPage * recordsPerPage;
  const indexOfFirstPartner = indexOfLastPartner - recordsPerPage;
  const currentPartners = partners.slice(indexOfFirstPartner, indexOfLastPartner);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(partners.length / recordsPerPage);

  return (
    <div>
      <ExhibitionContentNavbar />
      <AddPartner />

      <div className="container mt-4">
        {/* Partner List Section */}
        <div className="row">
          <div className="crd">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="hd-1">Partner List</div>

              <div>
                <label>Records Per Page:</label>
                <select
                  className="form-control"
                  value={recordsPerPage}
                  onChange={handleRecordsPerPageChange}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                </select>
              </div>
            </div>
            
            <hr />

            {/* Table Headers */}
            <div className="row text-start tblrowhd">
              <div className="col">Logo</div>
              <div className="col">Name</div>
              <div className="col">Partner Type</div> 
              <div className="col text-end">Action</div>
            </div>
            <hr />

            {/* Table Data */}
            {currentPartners.length > 0 ? (
              currentPartners.map((partner) => (
                <div key={partner.id} className="row user-table-data tblrowtd text-start">
                  <div className="col">
                    {partner.profile_image ? (
                      <img src={`${imageUrl}/partnerFiles/${partner.profile_image}`} alt={partner.name} width="50" />
                    ) : (
                      <span>No Logo</span>
                    )}
                  </div>
                  <div className="col">{partner.name}</div>
                  <div className="col">{partner.type}</div> 
                  <div className="col text-end">
                    <Link to={`/exhibition-dashboard/${exhibitionId}/edit-partner`} className="editbg">
                      <Edit className="ic1" />
                    </Link>

                    <span onClick={() => handleDelete(partner.id)}>
                      <Trash2 className="ic1" />
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="row">
                <div className="col text-center">No partners found.</div>
              </div>
            )}

            {/* Pagination and Records Per Page */}
            <div className="row">
              <div className="col d-flex justify-content-between align-items-center">
                {/* Pagination Controls */}
                <div>
                  <button 
                    className="btn btn-light" 
                    onClick={() => paginate(currentPage - 1)} 
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span className="mx-2">{currentPage}</span>
                  <button 
                    className="btn btn-light" 
                    onClick={() => paginate(currentPage + 1)} 
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>





            <div className="col-md-12 mt-5 mb-3 d-flex justify-content-between align-items-center">
                                      <span className="">
                                        <Link
                                          to={`/exhibition-dashboard/${exhibitionId}/sponser-details`}
                                          className="btnn mybtn"
                                        >
                                          <ArrowLeft className="ic text-white" />
                                          Back
                                        </Link>
                                      </span>
                                      <span className="">
                                        <Link
                                          to={`/exhibition-dashboard/${exhibitionId}/speaker-details`}
                                          className="btnn mybtn"
                                        >
                                          Next <ArrowRight className="ic text-white" />
                                        </Link>
                                      </span>
                                    </div>



          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionPartnerDetails;
