import React, { useState, useEffect } from "react"; 
import { baseurl, imageUrl } from "../Baseurl"; // Import baseurl
import axios from "axios";
import { Link } from 'react-router-dom'; 
import { Edit, Trash2, UserPlus } from "react-feather";
import { useConference } from "../../context/ConferenceContext";
import Swal from 'sweetalert2'; // Import SweetAlert2 
import ConferenceLeadsNavbar from "../utils/ConferenceLeadsNavbar";

const ConferenceDelegateLeads = () => { 
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [recordsPerPage, setRecordsPerPage] = useState(5); // State for records per page
  const { conferenceId } = useConference(); 
 

  // Handle change in records per page
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when records per page changes
  };

 
 
  return (
    <div>
      <ConferenceLeadsNavbar/>
      <div className="d-flex justify-content-between">
        <span><h1 className="">Delegate Leads</h1></span>
      </div>

      <div className="row mb-3">
            <div className="col-md-3">
              <div className="crd1 px-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <UserPlus className="ic2 cl1" size={45} />
                  </div>
                  <div>
                    <div className="txtd1">Total Leads</div>
                    <div className="txtd2">12</div>
                  </div>
                </div>
              </div>
            </div>
            
             
          </div>


      <div className="container mt-4">
        {/* Exhibitor List Section */}
        <div className="row">
          <div className="crd">
            <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="hd-1">Delegate Leads</div>

<div>
<label>Records Per Page:</label>
<select
      className="form-control"
      value={recordsPerPage}
      onChange={handleRecordsPerPageChange}
    >
      {/* <option value={5}>5 per page</option> */}
      <option value={10}>10</option>
      <option value={20}>20</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
      <option value={500}>500</option>
    </select>
</div>
            </div>
            

            <hr />

            {/* Table Headers */}
            <div className="row text-start tblrowhd">
              <div className="col">Image</div>
              <div className="col">Name</div>
              <div className="col">Designation</div>
              <div className="col">Company</div>
              <div className="col">Email</div>
              <div className="col">Phone</div>
              <div className="col">Booth No</div>
              <div className="col text-end">Action</div>
            </div>
            <hr />

            {/* Table Data */}
             
                <div className="row user-table-data tblrowtd text-start">
                  
                  <div className="col"> </div>
                  <div className="col"> </div>
                  <div className="col"> </div>
                  <div className="col"> </div>
                  <div className="col"> </div>
                  <div className="col"> </div>
                  <div className="col text-end">
                    <Link to={`/exhibition-dashboard/${conferenceId}/edit-exhibitor`} className="editbg">
                      <Edit className="ic1" />
                    </Link>

                   
                  </div>
                </div>
              
 
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConferenceDelegateLeads;
