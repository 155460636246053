import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Search, LogOut as UsersIcon } from "react-feather"; 
import { useConference } from "../../context/ConferenceContext";
const ConferenceContentNavbar = () => {
  const { conferenceId } = useConference();
  const location = useLocation(); // Get the current location

  // Function to check if the current path is the same as the given path
  const isActive = (path) => location.pathname === path;

  return (
    <div className="fixedtp30 mt-3">
      {/* Search Box */}
      <div className="chaintop">
        <span>
          <Link
            to={`/conference-dashboard/${conferenceId}/conference-basic-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${conferenceId}/conference-basic-details`)
                ? "active1"
                : ""
            }`}
          >
            Basic Details
          </Link>
        </span>
        <span>
          <Link
            to={`/conference-dashboard/${conferenceId}/conference-additional-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${conferenceId}/conference-additional-details`)
                ? "active1"
                : ""
            }`}
          >
            Additional Details
          </Link>
        </span>
        <span>
          <Link
            to={`/conference-dashboard/${conferenceId}/conference-time-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${conferenceId}/conference-time-details`)
                ? "active1"
                : ""
            }`}
          >
            Time Details
          </Link>
        </span>
        <span>
          <Link
            to={`/conference-dashboard/${conferenceId}/conference-venue-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${conferenceId}/conference-venue-details`)
                ? "active1"
                : ""
            }`}
          >
            Mode & Venue Details
          </Link>
        </span>
        <span>
          <Link
            to={`/conference-dashboard/${conferenceId}/conference-image-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${conferenceId}/conference-image-details`)
                ? "active1"
                : ""
            }`}
          >
            Image Files
          </Link>
        </span>
        
        <span>
          <Link
            to={`/conference-dashboard/${conferenceId}/image-files`}
            className={`spanli ${
              isActive(`/conference-dashboard/${conferenceId}/image-files`)
                ? "active1"
                : ""
            }`}
          >
            Brochure
          </Link>
        </span>

        <span>
          <Link
            to={`/conference-dashboard/${conferenceId}/conference-session-agenda`}
            className={`spanli ${
              isActive(`/conference-dashboard/${conferenceId}/conference-session-agenda`)
                ? "active1"
                : ""
            }`}
          >
            Session & Agenda
          </Link>
        </span>


         
        <span>
          <Link
            to={`/conference-dashboard/${conferenceId}/conference-speaker-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${conferenceId}/conference-speaker-details`)
                ? "active1"
                : ""
            }`}
          >
            Speakers
          </Link>
        </span>

        
        <span>
          <Link
            to={`/conference-dashboard/${conferenceId}/conference-sponser-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${conferenceId}/conference-sponser-details`)
                ? "active1"
                : ""
            }`}
          >
            Sponsers
          </Link>
        </span>
        <span>
          <Link
            to={`/conference-dashboard/${conferenceId}/conference-partner-details`}
            className={`spanli ${
              isActive(`/conference-dashboard/${conferenceId}/conference-partner-details`)
                ? "active1"
                : ""
            }`}
          >
            Partner
          </Link>
        </span>
      </div>
    </div>
  );
};

export default ConferenceContentNavbar;
