import React, { useState, useEffect } from "react";
import { useConference } from "../../context/ConferenceContext"; // Import the context hook for conference
import axios from "axios"; // Import axios
import { baseurl } from "../../component/Baseurl"; // Base URL for API calls
import { useNavigate } from "react-router-dom"; // Import navigate hook
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConferenceContentNavbar from "../utils/ConferenceContentNavbar"; // Import Navbar for conference
import Swal from 'sweetalert2';

const ConferenceVenueDetails = () => {
  // Destructure the context to get the conference ID
  const { conferenceId } = useConference();
  const navigate = useNavigate(); // For navigation after submission

  const org_id = localStorage.getItem("orgId");

  // State to track which toggle is active
  const [activeToggle, setActiveToggle] = useState(null);

  // State to hold form data including event_mode for conference
  const [formData, setFormData] = useState({
    venueName: '',
    venueAddress: '',
    venueMap: '',
    timezone: '',
    joiningLink: '',
    eventMode: '' // Added event_mode to state
  });

  // Fetch venue details on mount for conference
  useEffect(() => {
    const fetchVenueDetails = async () => {
      try {
        const response = await axios.get(`${baseurl}/Get_ConferenceVenueDetails/${conferenceId}`);
        
        // Log the entire response to check the structure
        console.log('API Response:', response);
  
        const venueDetails = response.data.conferenceDetails[0]; // Access conferenceDetails instead of venueDetails
  
        if (venueDetails) {
          setFormData({
            venueName: venueDetails.venue_name,
            venueAddress: venueDetails.venue_address,
            venueMap: venueDetails.venue_map,
            timezone: venueDetails.timezone || '',
            joiningLink: venueDetails.joining_link || '',
            eventMode: venueDetails.event_mode || ''
          });
  
          // Set active toggle based on eventMode
          setActiveToggle(venueDetails.event_mode);
        }
      } catch (error) {
        console.error("Error fetching conference venue details:", error);
        // toast.error("Error fetching venue details. Please try again.");
      }
    };
  
    if (conferenceId) {
      fetchVenueDetails();
    }
  }, [conferenceId]);
  


  // Handle toggle change for conference event modes (InPerson, Virtual, Hybrid)
  const handleToggleChange = (toggle) => {
    setActiveToggle(activeToggle === toggle ? null : toggle); // Toggle on/off
    setFormData({ ...formData, eventMode: toggle }); // Update eventMode based on the toggle
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission for conference venue details
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { venueName, venueAddress, venueMap, timezone, joiningLink, eventMode } = formData;
  
    // Validate form data (basic validation)
    if (!venueName || !venueAddress || !venueMap || (activeToggle === 'virtual' || activeToggle === 'hybrid') && (!timezone || !joiningLink)) {
      toast.error("Please fill in all required fields.");
      return;
    }
  
    try {
      // Prepare data to send to the backend for conference venue
      const formDataToSend = {
        conferenceId, // Use conferenceId instead of exhibitionId
        orgId: org_id,
        venueName,
        venueAddress,
        venueMap,
        timezone,
        joiningLink,
        eventMode // Send eventMode in the request data
      };
  
      // Log the formDataToSend for debugging
      console.log('Data sent to backend:', formDataToSend);
  
      // Send POST request to the backend API for conference venue details
      const response = await axios.post(`${baseurl}/Insert_conferenceVenueDetails`, formDataToSend);
  
      if (response.status === 200) {
        // Show success message using SweetAlert2
        Swal.fire({
          title: 'Success!',
          text: 'Conference venue details submitted successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          // Navigate to next step after success (conference-related step)
          navigate(`/conference-dashboard/${conferenceId}/conference-image-details`);
        });
      } else {
        throw new Error("Submission failed");
      }
    } catch (error) {
      console.error("Error submitting conference venue details:", error);
      toast.error("Error submitting conference venue details. Please try again later.");
    }
  };
  

  return (
    <div>
      <ConferenceContentNavbar /> {/* Conference-specific navbar */}
      <h1 className="mt-3">Conference Mode & Venue Details</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-3">
            <form onSubmit={handleSubmit}>
              <div className="row g-2">
                <div className="col-md-12">
                  <div className="form-check form-switch d-inline-block me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckInPerson"
                      checked={activeToggle === 'inPerson'}
                      onChange={() => handleToggleChange('inPerson')}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckInPerson">InPerson</label>
                  </div>

                  <div className="form-check form-switch d-inline-block me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckVirtual"
                      checked={activeToggle === 'virtual'}
                      onChange={() => handleToggleChange('virtual')}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckVirtual">Virtual</label>
                  </div>

                  <div className="form-check form-switch d-inline-block">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckHybrid"
                      checked={activeToggle === 'hybrid'}
                      onChange={() => handleToggleChange('hybrid')}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckHybrid">Hybrid</label>
                  </div>
                </div>

                {/* Conditionally render inputs based on active toggle for conference */}
                {activeToggle === 'inPerson' || activeToggle === 'hybrid' ? (
                  <>
                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="venueName"
                        className="form-control"
                        placeholder="Enter Venue Name"
                        value={formData.venueName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Address <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Venue Address"
                        rows="3"
                        name="venueAddress"
                        value={formData.venueAddress}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Map <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Map Iframe Code"
                        rows="3"
                        name="venueMap"
                        value={formData.venueMap}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                  </>
                ) : null}

                {activeToggle === 'virtual' || activeToggle === 'hybrid' ? (
                  <>
                    <div className="col-md-12">
                      <label className="form-label">
                        Select TimeZone <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Timezone"
                        rows="3"
                        name="timezone"
                        value={formData.timezone}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Joining Link <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Joining Link"
                        rows="3"
                        name="joiningLink"
                        value={formData.joiningLink}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                  </>
                ) : null}

              </div>

              <div className="col-md-12 mt-3 text-end">
                <button type="submit" className="btnn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ToastContainer /> {/* Toast container for notifications */}
    </div>
  );
};

export default ConferenceVenueDetails;
