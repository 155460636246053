import React, { useState, useEffect } from "react";
import { useConference } from "../../context/ConferenceContext";
import { PlusCircle } from "react-feather";
import axios from "axios";
import { baseurl } from "../../component/Baseurl";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ConferenceContentNavbar from "../utils/ConferenceContentNavbar";

const ConferenceTimeDetails = () => {
  const { conferenceId } = useConference();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const org_id = localStorage.getItem("orgId");
  const navigate = useNavigate();

  // Fetch existing conference time details
  const fetchConferenceTimeDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseurl}conferenceTime/${conferenceId}`);
      const fetchedData = response.data;
  
      if (fetchedData.length > 0) {
        const formattedRows = fetchedData.map((item) => ({
          id: item.id,
          timezone: item.timezone,
          selected_date: item.selected_date.split("T")[0], // Format date
          start_time: item.start_time,
          end_time: item.end_time,
        }));
  
        setRows(formattedRows);
      } else {
        await fetchConferenceDates(); // If no records, fetch dates
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // If 404 error occurs, fetch conference dates
        await fetchConferenceDates();
      } else {
        console.error("Error fetching conference time details:", error);
      }
    } finally {
      setLoading(false);
    }
  };
  

  // Fetch conference date range
  const fetchConferenceDates = async () => {
    try {
      const response = await axios.get(`${baseurl}/GetConferenceDates/${conferenceId}`);
      const { conference_start_date, conference_end_date } = response.data;

      const startDate = new Date(conference_start_date);
      const endDate = new Date(conference_end_date);
      const range = [];

      while (startDate <= endDate) {
        range.push(new Date(startDate).toISOString().split("T")[0]);
        startDate.setDate(startDate.getDate() + 1);
      }

      const populatedRows = range.map((date, index) => ({
        id: Date.now() + index,
        selected_date: date,
        start_time: "09:00",
        end_time: "18:00",
        timezone: "",
      }));

      setRows(populatedRows);
      setDateRange(range);
    } catch (error) {
      console.error("Error fetching conference dates:", error);
    }
  };

  // useEffect to fetch data on load
  useEffect(() => {
    if (conferenceId) {
      fetchConferenceTimeDetails(); // Fetch conference time details initially
    }
  }, [conferenceId]);

  const handleInputChange = (e, id, field) => {
    const { value } = e.target;

    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      )
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = rows.map((row) => ({
      timezone: e.target[`timezone-${row.id}`].value,
      selected_date: row.selected_date,
      start_time: e.target[`startTime-${row.id}`].value,
      end_time: e.target[`endTime-${row.id}`].value,
    }));

    const payload = {
      org_id: org_id,
      conference_id: conferenceId,
      data: formData,
    };

    try {
      const response = await axios.post(`${baseurl}/Insert_ConferenceTimeDetail`, payload);
      Swal.fire({
        title: "Success!",
        text: "Conference time details have been updated successfully.",
        icon: "success",
        confirmButtonText: "Continue",
      }).then(() => {
        navigate(`/conference-dashboard/${conferenceId}/conference-venue-details`);
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to submit conference time details.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div>
      <ConferenceContentNavbar />
      <h1 className="mt-3">Update The Time Table of Conference</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-3">
            <form onSubmit={handleSubmit}>
              {loading && (
                <div className="d-flex justify-content-center mb-3">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}

              {rows.map((row) => (
                <div key={row.id}>
                  <div className="row g-2 mb-3">
                    <div className="col">
                      <label className="form-label">
                        Timezone<span className="text-danger">*</span>
                      </label>
                      <select
                        name={`timezone-${row.id}`}
                        className="form-control"
                        value={row.timezone}
                        onChange={(e) => handleInputChange(e, row.id, "timezone")}
                        required
                      >
                        <option value="">Select Timezone</option>
                        <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
                        <option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
                        <option value="-10:00">(GMT -10:00) Hawaii</option>
                        <option value="-09:00">(GMT -9:00) Alaska</option>
                        <option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                      </select>
                    </div>

                    <div className="col">
                      <label className="form-label">
                        Selected Date<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={row.selected_date}
                        readOnly
                      />
                    </div>

                    <div className="col">
                      <label className="form-label">
                        Start Time<span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name={`startTime-${row.id}`}
                        className="form-control"
                        value={row.start_time}
                        onChange={(e) => handleInputChange(e, row.id, "start_time")}
                        required
                      />
                    </div>

                    <div className="col">
                      <label className="form-label">
                        End Time<span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name={`endTime-${row.id}`}
                        className="form-control"
                        value={row.end_time}
                        onChange={(e) => handleInputChange(e, row.id, "end_time")}
                        required
                      />
                    </div>
                  </div>
                  <hr className="mt-2" />
                </div>
              ))}

              <div className="col-md-12 mt-3 text-end">
                
                <button type="submit" className="btnn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConferenceTimeDetails;
