import React from "react";
import Sidebar from "../component/Sidebar";
import Topbar from "../component/utils/Topbar";
import LineChart from "../component/utils/LineChart";
import BarChart from "../component/utils/BarChart";
import PieChart from "../component/utils/PieChart";
import { Heart, UserPlus, Edit } from "react-feather";
import FooterTwo from "../component/utils/FooterTwo";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <>
      <div>
        <Topbar />

        <div className="text-start">
          <h1 className="mt-3">Dashboard</h1>
          <p className="mb-2">Welcome to the Dashboard.</p>
        </div>

        <div className="">
          <div className="row mb-3">
            <div className="col-md-3">
              <div className="crd1 px-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <UserPlus className="ic2 cl1" size={45} />
                  </div>
                  <div>
                    <div className="txtd1">My Exhibition</div>
                    <div className="txtd2">12</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="crd1 px-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <UserPlus className="ic2 cl2" size={45} />
                  </div>
                  <div>
                    <div className="txtd1">My Conference</div>
                    <div className="txtd2">89</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="crd1 px-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <UserPlus className="ic2 cl3" size={45} />
                  </div>
                  <div>
                    <div className="txtd1">Users</div>
                    <div className="txtd2">1</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="crd1 px-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <UserPlus className="ic2 cl4" size={45} />
                  </div>
                  <div>
                    <div className="txtd1">Users</div>
                    <div className="txtd2">89</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3 mb-3">
            <div className="col-md-8">
              <div className=" crd1">
                <LineChart />
              </div>
            </div>

            <div className="col-md-4">
              <div className="crd1">
                <PieChart />
              </div>
            </div>

            <div className="col-md-4">
              <div className="crd1">
                <PieChart />
              </div>
            </div>

            <div className="col-md-8">
              <div className="crd1">
                <BarChart />
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-md-9">
              <div className="crd1">
                <div className="hd-1">System Users List</div>

                <hr />

                {/* Table Headers */}
                <div className="row text-start tblrowhd m-0">
                  <div className="col">Name</div>
                  <div className="col">Email</div>
                  <div className="col">Department</div>
                  <div className="col">Designation</div>
                  <div className="col">User Role</div>

                  <div className="col text-end">Action</div>
                </div>
                <hr />

                <div className="row user-table-data tblrowtd text-start m-0">
                  <div className="col">Kunal Gupta</div>
                  <div className="col">test@email.com</div>
                  <div className="col">teastea</div>
                  <div className="col">Test Data</div>
                  <div className="col">test gags</div>
                  <div className="col text-end">
                    <Link to="/" className="editbg">
                      <Edit className="ic1" />
                    </Link>
                  </div>
                </div>

                <div className="row user-table-data tblrowtd text-start m-0">
                  <div className="col">Kunal Gupta</div>
                  <div className="col">test@email.com</div>
                  <div className="col">teastea</div>
                  <div className="col">Test Data</div>
                  <div className="col">test gags</div>
                  <div className="col text-end">
                    <Link to="/" className="editbg">
                      <Edit className="ic1" />
                    </Link>
                  </div>
                </div>

                <div className="row user-table-data tblrowtd text-start m-0">
                  <div className="col">Kunal Gupta</div>
                  <div className="col">test@email.com</div>
                  <div className="col">teastea</div>
                  <div className="col">Test Data</div>
                  <div className="col">test gags</div>
                  <div className="col text-end">
                    <Link to="/" className="editbg">
                      <Edit className="ic1" />
                    </Link>
                  </div>
                </div>

                <div className="row user-table-data tblrowtd text-start m-0">
                  <div className="col">Kunal Gupta</div>
                  <div className="col">test@email.com</div>
                  <div className="col">teastea</div>
                  <div className="col">Test Data</div>
                  <div className="col">test gags</div>
                  <div className="col text-end">
                    <Link to="/" className="editbg">
                      <Edit className="ic1" />
                    </Link>
                  </div>
                </div>

                <div className="row user-table-data tblrowtd text-start m-0">
                  <div className="col">Kunal Gupta</div>
                  <div className="col">test@email.com</div>
                  <div className="col">teastea</div>
                  <div className="col">Test Data</div>
                  <div className="col">test gags</div>
                  <div className="col text-end">
                    <Link to="/" className="editbg">
                      <Edit className="ic1" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="crd1">
                <div className="hd-1">System Users List</div>
              </div>
            </div>
          </div>
        </div>

        <FooterTwo />
      </div>
    </>
  );
};

export default Home;
