import React, { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap"; // Import Bootstrap components
import { useExhibition } from "../../context/ExhibitionContext";
import axios from "axios";
import { baseurl } from "../Baseurl";
import { Edit, Trash2, PlusCircle } from "react-feather";

const MyCustomForm = ({ formId }) => {
  const [fields, setFields] = useState([]); // Store dynamic form fields
  const [formData, setFormData] = useState({
    formName: "", // Store form name
  });
  const { exhibitionId } = useExhibition(); // Get exhibitionId from context
  //   const [formId] = useState(2);
  const [isSubmitting, setIsSubmitting] = useState(false); // For loading state

  // Fetch form details when the component loads
  useEffect(() => {
    const fetchFormDetails = async () => {
      try {
        const response = await axios.get(
          `${baseurl}/getCustomFormsById/${formId}`
        );
        const { form_name, fields } = response.data;
        setFormData((prev) => ({ ...prev, formName: form_name }));
        setFields(fields); // Set fetched fields
      } catch (error) {
        console.error("Error fetching form:", error);
        alert("Failed to fetch form details.");
      }
    };

    fetchFormDetails();
  }, [formId]);

  // Handle change in field input (text, number, radio, checkbox, etc.)
  const handleFieldChange = (index, value) => {
    setFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index].value = value; // Store the value in the field object
      return updatedFields;
    });
  };

  // Save form data to the database
  const handleSubmitForm = async () => {
    // Log the form data to the console before submitting
    console.log("Submitted data:", {
      fields, // This will show all the field values
      eventId: exhibitionId,
      formId: formId,
      formName: formData.formName, // Form name
    });

    setIsSubmitting(true); // Set submitting state

    try {
      const response = await axios.post(`${baseurl}/submitformdata`, {
        fields, // Send the fields with values
        eventId: exhibitionId,
        formId: formId,
        formName: formData.formName,
      });

      // Check if the response status is 200 (successful)
      if (response.status === 200) {
        alert("Form saved successfully!");
      } else {
        alert("Failed to save form.");
      }
    } catch (error) {
      console.error("Error saving form:", error);
      alert("An error occurred while saving the form.");
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between">
        <div>
          <h1 className="text-capitalize">Previewing Deligate Form</h1>
        </div>
        <div>
          <div variant="outline-danger" className="deletvari text-white">
            <Trash2 size={20} /> Delete & Create New Form
          </div>
        </div>
      </div>

      {/* Dynamic Form Preview */}
      <div className="mt-4">
        <div className="row"> 
            <div className="crd">
              {/* Form Name Input */}
              <div class="hd-1 text-capitalize">{formData.formName}</div>
              <hr />

              <div className="row p-3">
                <div className="col-md-8 border rounded">
                  <div className="row my-3 justify-content-center">
                    
                    <div className="text-center ">
                      <span className="text-danger">
                        <strong>*Default Inputs*</strong>
                      </span>
                    </div>
                    <div className="col-12 mb-2">
                      <hr />
                    </div>
                  </div>

                  <div className="row">
                  <div className="col-md-6">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="formName"
                      className="form-control"
                      placeholder="Enter Name Here"
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">
                      Mobile No. <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="formName"
                      className="form-control"
                      placeholder="Enter Mobile No. Here"
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="formName"
                      className="form-control"
                      placeholder="Enter Email Here"
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">
                      Designation <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="formName"
                      className="form-control" 
                      placeholder="Enter Designation Here"
                      required
                    />
                  </div>
                  </div>

                  

                  <div className="row my-3 justify-content-center">
                    
                    <div className="text-center ">
                      <span className="text-danger">
                        <strong>*Custom Inputs*</strong>
                      </span>
                    </div>
                    <div className="col-12 mb-2">
                      <hr />
                    </div>
                  </div>

                  {/* Display Added Fields */}
                  {fields.length > 0 ? (
                    fields.map((field, index) => (
                      <div key={index} className="col-md-12 ">
                        <label className="me-2">{field.label}:</label>
                        {field.type === "text" && (
                          <input
                            type="text"
                            className="form-control"
                            value={field.value || ""}
                            onChange={(e) =>
                              handleFieldChange(index, e.target.value)
                            }
                          />
                        )}
                        {field.type === "number" && (
                          <input
                            type="number"
                            className="form-control"
                            value={field.value || ""}
                            onChange={(e) =>
                              handleFieldChange(index, e.target.value)
                            }
                          />
                        )}
                        {field.type === "textarea" && (
                          <textarea
                            className="form-control"
                            value={field.value || ""}
                            rows="4"
                            onChange={(e) =>
                              handleFieldChange(index, e.target.value)
                            }
                          ></textarea>
                        )}
                        {field.type === "radio" &&
                          JSON.parse(field.options).map((option, i) => (
                            <div key={i}>
                              <input
                                type="radio"
                                name={field.label}
                                value={option}
                                className="form-check-input "
                                checked={field.value === option}
                                onChange={(e) =>
                                  handleFieldChange(index, e.target.value)
                                }
                              />{" "}
                              {option}
                            </div>
                          ))}
                        {field.type === "checkbox" &&
                          JSON.parse(field.options).map((option, i) => (
                            <div key={i}>
                              <input
                                type="checkbox"
                                checked={field.value?.includes(option)}
                                onChange={(e) => {
                                  let newValue = [...(field.value || [])];
                                  if (e.target.checked) {
                                    newValue.push(option);
                                  } else {
                                    newValue = newValue.filter(
                                      (opt) => opt !== option
                                    );
                                  }
                                  handleFieldChange(index, newValue);
                                }}
                              />{" "}
                              {option}
                            </div>
                          ))}
                        {field.type === "file" && (
                          <input
                            type="file"
                            className="form-control"
                            onChange={(e) =>
                              handleFieldChange(index, e.target.files)
                            }
                          />
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No fields added yet.</p>
                  )}

                  {fields.length > 0 && (
                    <div className="mt-3 text-end mb-3">
                      <Button
                        variant="success"
                        onClick={handleSubmitForm}
                        // disabled={isSubmitting}
                        // disabled 
                      >
                        {isSubmitting ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Submit Form"
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          
        </div>
      </div>

      {/* Submit Form Button */}
    </div>
  );
};

export default MyCustomForm;
