import React from 'react';
import { useExhibition } from '../../context/ExhibitionContext';  // Import the context hook

const ExhibitionDetails = () => {
  // Destructure the context to get the exhibition ID
  const { exhibitionId } = useExhibition(); 

  return (
    <div>
      <h3 className="text-dark">Exhibition Details</h3>
      <p>Exhibition ID: {exhibitionId}</p> {/* Print the exhibitionId */}
      <p>Details about the exhibition...</p>
    </div>
  );
};

export default ExhibitionDetails;
