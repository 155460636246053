import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { baseurl } from "../../component/Baseurl";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useConference } from "../../context/ConferenceContext";
import ConferenceContentNavbar from "../utils/ConferenceContentNavbar";

const ConferenceBasicDetails = () => {
  const { conferenceId } = useConference();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    conferenceName: "",
    organisedBy: "",
    rangeAttendees: "",
    frequency: "",
  });

  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const org_id = localStorage.getItem("orgId");

  useEffect(() => {
    if (!org_id || !conferenceId) {
      toast.error("Organization ID or Conference ID is missing!");
      return;
    }

    const fetchConferenceData = async () => {
      try {
        const response = await axios.get(
          `${baseurl}conferenceBasicDetails/${org_id}/${conferenceId}`
        );
        const { conference, industries } = response.data;

        // Update form data based on API response
        setFormData({
          conferenceName: conference.conference_name,
          organisedBy: conference.organised_by,
          attendees: conference.range_attendees,
          frequency: conference.conference_frequency,
        });

        setSelectedIndustries(industries.map((industry) => industry.industry));
        setStartDate(new Date(conference.conference_start_date));
        setEndDate(new Date(conference.conference_end_date));
      } catch (error) {
        toast.error("Error fetching conference details!");
        console.error(error);
      }
    };

    fetchConferenceData();
  }, [conferenceId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue && !selectedIndustries.includes(selectedValue)) {
      setSelectedIndustries((prev) => [...prev, selectedValue]);
    }
  };

  const handleIndustryClick = (industry) => {
    setSelectedIndustries((prev) => prev.filter((item) => item !== industry));
  };

  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      toast.error("End date must be after the start date.");
      setEndDate(null);
    } else {
      setEndDate(date);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Log the data being sent
    console.log("Data to be sent:", {
      org_id,
      conferenceId,
      ...formData,
      selectedIndustries,
      startDate,
      endDate,
    });
  
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to update the conference details?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, Update it!",
      cancelButtonText: "No, Cancel!",
    });
  
    if (result.isConfirmed) {
      try {
        const response = await axios.post(`${baseurl}update-conference`, {
          org_id,
          conferenceId,
          ...formData,
          selectedIndustries,
          startDate,
          endDate,
        });
  
        if (response.status === 200) {
          Swal.fire("Updated!", "Conference details updated successfully!", "success");
          setTimeout(() => {
            navigate(`/conference-dashboard/${conferenceId}/conference-additional-details`);
          }, 1000);
        }
      } catch (error) {
        console.error("API Error:", error);
        Swal.fire("Error", "Error updating conference details!", "error");
      }
    }
  };
  

  return (
    <div>
      <ConferenceContentNavbar />
      <h1 className="mt-3">Conference Basic Details</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-3">
            <form onSubmit={handleSubmit}>
              <div className="row g-2">
                <div className="col-md-12">
                  <label className="form-label">
                    Conference Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="conferenceName"
                    className="form-control"
                    placeholder="Enter Conference Name"
                    value={formData.conferenceName}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-md-12">
                  <label className="form-label">
                    Organised By <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="organisedBy"
                    className="form-control"
                    placeholder="Enter Organiser Name"
                    value={formData.organisedBy}
                    onChange={handleInputChange}
                    required
                  />
                </div>


                <div className="col-md-6">
                  <label className="form-label">
                    Conference Industries
                  </label>
                  <textarea
                    className="txtarea"
                    rows="4"
                    placeholder="Selected Industries"
                    value={selectedIndustries.join(", ")}
                    readOnly
                  ></textarea>
                </div>

                <div className="col-md-6">
                  <label className="form-label">
                    Add Industries <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    onChange={handleDropdownChange}
                  >
                    <option value="">Select Industry</option>
                    <option value="Technology">Technology</option>
                    <option value="Education">Education</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Finance">Finance</option>
                  </select>
                  <div className="selected-industries mt-2">
                    {selectedIndustries.map((industry, index) => (
                      <span
                        key={index}
                        className="industry-item"
                        onClick={() => handleIndustryClick(industry)}
                        style={{
                          textDecoration: "line-through",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                      >
                        {industry}
                      </span>
                    ))}
                  </div>
                </div>

                


                <div className="col-md-4">
                      <label className="form-label">
                        Range of Attendees <span className="text-danger">*</span>
                      </label>
                      <select
                        name="attendees"
                        className="form-control"
                        value={formData.attendees}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Attendee Range</option>
                        <option value="0-50">0-50</option>
                        <option value="50-100">50-100</option>
                        <option value="100-200">100-200</option>
                        <option value="200-500">200-500</option>
                        <option value="500+">500+</option>
                      </select>
                    </div>

                <div className="col-md-4">
                  <label className="form-label">
                    Start Date <span className="text-danger">*</span>
                  </label>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Select Start Date"
                    required
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">
                    End Date <span className="text-danger">*</span>
                  </label>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    placeholderText="Select End Date"
                    required
                    minDate={startDate || new Date()}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">
                    Frequency <span className="text-danger">*</span>
                  </label>
                  <select
                    name="frequency"
                    className="form-control"
                    value={formData.frequency}
                    onChange={handleInputChange}
                    required
                  > 
                        <option value="One Time Event">One Time Event</option>
                        <option value="Every Week">Every Week</option>
                        <option value="Every Month">Every Month</option>
                        <option value="Once In 3 Month">Once In 3 Month</option>
                        <option value="Once In 6 Month">Once In 6 Month</option>
                        <option value="Every Year">Every Year</option>
                  </select>
                </div>
              </div>

              <div className="col-md-12 mt-3 text-end">
                <button type="submit" className="btnn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConferenceBasicDetails;
