import React, { useState, useEffect } from "react";
import axios from "axios"; // For API calls
import { useNavigate } from "react-router-dom";
import { baseurl } from "../component/Baseurl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateBranch = () => {
    const navigate = useNavigate();
    
    // State for form fields
    const [formData, setFormData] = useState({
      branch_name: "",
      country_code: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    });
  
    // State for dropdown options
    const [countryCodes, setCountryCodes] = useState([]);
    const [countries, setCountries] = useState([]);
  
    // Handle input change
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    // Fetch country codes and countries (you can replace this with an actual API request)
    useEffect(() => {
      setCountryCodes([
        { code: "+91", label: "India" },
        // { code: "+1", label: "USA" },
        // { code: "+44", label: "UK" },
        ,
      ]);
  
      setCountries([
        { code: "IN", name: "India" },
        // { code: "US", name: "United States" },
        // { code: "GB", name: "United Kingdom" },
      ]);
    }, []);
  
    // Form submission handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Retrieve orgId from localStorage
        const orgId = localStorage.getItem("orgId");
    
        // Log the orgId and form data
        console.log("Organization ID:", orgId);
        
        // Check if orgId exists, if not, log an error
        if (!orgId) {
            console.error("Organization ID is missing from localStorage");
            return; // Prevent form submission if orgId is missing
        }
    
        // Prepare the data to be sent with organization_id included
        const dataToSend = {
            ...formData,  // Spread the form data
            organization_id: orgId,  // Add the organization_id from localStorage
        };
    
        // Log the data being sent to verify the structure
        console.log("Data to send to API:", dataToSend);
    
        try {
            // Send the data to the backend
            const response = await axios.post(`${baseurl}create-branch`, dataToSend);
            if (response.status === 201) {
                // Show success message
                toast.success('Branch added successfully!', {
                    position: "top-right",
                    autoClose: 2000,  // Show for 2 seconds
                    hideProgressBar: true,
                    closeOnClick: true,
                });
    
                // Redirect to "create-user" after 2 seconds
                setTimeout(() => {
                    navigate("/create-user");
                }, 2000); // 2 seconds delay
            }
        } catch (error) {
            console.error("Error creating branch", error);
            // Handle error, show an error message
            toast.error('Failed to add branch!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
            });
        }
    };
  
  
  

  return (
    <section className="bg-signup">
      <div className="tp-hdr sticky-top mb-4 d-flex align-items-center px-4">
        <span className="tptxt">Create Branch</span>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="my-card p-5">
              <div className="">
                <h3 className="header-title">Create New Branch</h3>

                <div className="register mt-3">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                       

                      <div className="col-md-12">
                        <label htmlFor="branch_name" className="w-100">
                          <input
                            type="text"
                            id="branch_name"
                            name="branch_name"
                            value={formData.branch_name}
                            onChange={handleChange}
                            placeholder="Branch Name"
                            required
                          />
                          <span>Branch Name</span>
                        </label>
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="country_code" className="w-100">
                          <select
                            id="country_code"
                            name="country_code"
                            value={formData.country_code}
                            onChange={handleChange}
                            required
                          >
                            {/* <option value="">Select Country Code</option> */}
                            {countryCodes.map((code) => (
                              <option key={code.code} value={code.code}>
                                {code.label} ({code.code})
                              </option>
                            ))}
                          </select>
                          <span></span>
                        </label>
                      </div>

                      <div className="col-md-9">
                        <label htmlFor="phone" className="w-100">
                          <input
                            type="text"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Phone"
                            required
                          />
                          <span>Branch Phone</span>
                        </label>
                      </div>

                      <div className="col-md-12">
                        <label htmlFor="address" className="w-100">
                          <input
                            type="text"
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            placeholder="Address"
                            required
                          />
                          <span>Branch Address</span>
                        </label>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="city" className="w-100">
                          <input
                            type="text"
                            id="city"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            placeholder="City"
                            required
                          />
                          <span>City</span>
                        </label>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="state" className="w-100">
                          <input
                            type="text"
                            id="state"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            placeholder="State"
                            required
                          />
                          <span>State</span>
                        </label>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="country" className="w-100">
                          <select
                            id="country"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          >
                            {/* <option value="">Select Country</option> */}
                            {countries.map((country) => (
                              <option key={country.code} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                          <span> </span>
                        </label>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="pincode" className="w-100">
                          <input
                            type="text"
                            id="pincode"
                            name="pincode"
                            value={formData.pincode}
                            onChange={handleChange}
                            placeholder="Pincode"
                            required
                          />
                          <span>Pincode</span>
                        </label>
                      </div>

                      <div className="col-md-12 mt-4 text-end">
                        <button type="submit" className="btnn">
                          Create Branch
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default CreateBranch;
