// src/contexts/ConferenceContext.js
import React, { createContext, useContext, useState } from 'react';

// Create the ConferenceContext
const ConferenceContext = createContext();

export const ConferenceProvider = ({ children }) => {
  const [conferenceId, setConferenceId] = useState(null);

  return (
    <ConferenceContext.Provider value={{ conferenceId, setConferenceId }}>
      {children}
    </ConferenceContext.Provider>
  );
};

export const useConference = () => {
  return useContext(ConferenceContext);
};
