import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import { baseurl as BASE_URL } from "../component/Baseurl";
import logo from '../component/img/logo.png'
import { useNavigate } from "react-router-dom";


const Login = () => {
  const [emailOrPhone, setEmailOrPhone] = useState(""); // State to store user input
  const [error, setError] = useState(""); // For displaying errors
  const [isRedirecting, setRedirecting] = useState(false); // State to handle redirect after success

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Log the email or phone being sent to the API
    console.log("Sending data:", { email: emailOrPhone });
  
    try {
      // Make API call to send OTP
      const response = await axios.post(`${BASE_URL}/send-otp`, {
        email: emailOrPhone,
      });
  
      // Log the response from the API
      console.log("API Response:", response);
  
      if (response.status === 200) {
        toast.success("OTP sent successfully to your email!", { autoClose: 3000 });
        
        // Encode email to Base64
        const encodedEmail = btoa(emailOrPhone); // Convert email to Base64
  
        // Redirect to OTP verification page with the email encoded in the URL
        setRedirecting(true);
  
        // Redirect after a slight delay
        setTimeout(() => {
          navigate(`/verify-otp?email=${encodedEmail}`);
        }, 2000); // Adjust the timeout if necessary
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setError("There was an error sending OTP. Please try again later.");
      toast.error("Failed to send OTP. Please check your email and try again.", { autoClose: 3000 });
    }
  };
  
  // Hook for navigation
  const navigate = useNavigate();
  
  // Redirect to OTP verification page after OTP is sent
  if (isRedirecting) {
    return <Navigate to="/verify-otp" />;
  }

  return (
    <section className="bg-signup d-flex align-items-center bglogin">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="my-card p-5">
              <div className="text-center">
                <div>
                  <img src={logo} className="img-fluid logo" alt="Eventoz Logo" />
                </div>
              </div>

              <h3 className="header-title">Login / Signin</h3>
              <div className="register mt-3">
                <form onSubmit={handleSubmit}>

                <div className="">
                      <label for="inputPassword5" class="form-label">
                      Enter Your Email<span className="text-danger">*</span>
                      </label>

                      <input
                      type="text"
                      id="emailOrPhone" class="form-control"
                      placeholder="Email Here.."
                      required
                      value={emailOrPhone}
                      onChange={(e) => setEmailOrPhone(e.target.value)} // Set state on input change
                    />

                      
                    </div>


                  {/* <label htmlFor="emailOrPhone">
                    <input
                      type="text"
                      id="emailOrPhone"
                      placeholder="Email or Phone"
                      required
                      value={emailOrPhone}
                      onChange={(e) => setEmailOrPhone(e.target.value)}   
                    />
                    <span>Enter Your Email</span>
                  </label> */}

                  <div className="text-center">
                    <button type="submit" className="btnn">
                      Continue
                    </button>
                  </div>
                </form>

                {/* Display error message if any */}
                {error && <div className="text-danger text-center mt-3">{error}</div>}

                <hr className="mt-4 mb-4" />
                <p className="text-muted ptxt">
                  We’re committed to your privacy. For more information, check
                  out our <Link to="/privacy-policy">Privacy Policy</Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
