import React, { useState, useEffect } from "react";
import { useExhibition } from "../../context/ExhibitionContext";
import { PlusSquare, Trash2, PlusCircle, ArrowRight, ArrowLeft } from "react-feather";
import axios from "axios";
import { baseurl } from "../../component/Baseurl";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import { Link } from "react-router-dom"; 

const ExhibitionTimeDetails = () => {
  const { exhibitionId } = useExhibition();
  const [rows, setRows] = useState([{ id: Date.now() }]);
  const [loading, setLoading] = useState(false); // State to manage loading
  const [dateRange, setDateRange] = useState([]); // State to hold the date range
  const org_id = localStorage.getItem("orgId");
  const navigate = useNavigate();

  const handleAddRow = () => {
    setRows([...rows, { id: Date.now() }]);
  };

  const handleDeleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = rows.map((row) => ({
      timezone: e.target[`timezone-${row.id}`].value,
      selected_date: e.target[`date-${row.id}`].value,
      start_time: e.target[`startTime-${row.id}`].value,
      end_time: e.target[`endTime-${row.id}`].value,
    }));
  
    const payload = {
      org_id: org_id,
      exhibition_id: exhibitionId,
      data: formData,
    };
  
    try {
      const response = await axios.post(`${baseurl}/Insert_ExhibitionTimeDetail`, payload);
      console.log("Form submitted successfully:", response.data);
  
      // Show success message (in browser console or simple alert)
      console.log("Event time details have been updated successfully.");
      // alert("Event time details have been updated successfully.");
  
      // Redirect to the next page after success
      navigate(`/exhibition-dashboard/${exhibitionId}/image-files`);
    } catch (error) {
      console.error("Error submitting form:", error);
  
      // Show error message (in browser console or simple alert)
      alert("Failed to submit event time details.");
    }
  };
  
  

  const fetchEventTimeDetails = async () => {
    setLoading(true); // Set loading to true while fetching
    try {
      const response = await axios.get(`${baseurl}/exhibitionTime/${exhibitionId}`);
      const fetchedData = response.data;
      const formattedRows = fetchedData.map((item) => ({
        id: item.id,
        timezone: item.timezone,
        selected_date: item.selected_date.split("T")[0],
        start_time: item.start_time,
        end_time: item.end_time,
      }));
      setRows(formattedRows);
    } catch (error) {
      // console.error("Error fetching event time details:", error);
    } finally {
      setLoading(false); // Set loading to false after the request completes
    }
  };

  const fetchExhibitionDates = async () => {
    try {
      const response = await axios.get(`${baseurl}/GetExhibitionDates/${exhibitionId}`);
      const { exhibition_start_date, exhibition_end_date } = response.data;
      
      // Generate the date range from start to end date
      const startDate = new Date(exhibition_start_date);
      const endDate = new Date(exhibition_end_date);
      const range = [];
      
      while (startDate <= endDate) {
        range.push(new Date(startDate).toISOString().split("T")[0]);
        startDate.setDate(startDate.getDate() + 1); // Move to the next date
      }

      setDateRange(range); // Set the date range in state
    } catch (error) {
      console.error("Error fetching exhibition dates:", error);
    }
  };

  useEffect(() => {
    if (exhibitionId) {
      fetchExhibitionDates();
      fetchEventTimeDetails();
    }
  }, [exhibitionId]);

  const handleInputChange = (e, id, field) => {
    const { name, value } = e.target;

    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      )
    );
  };

  return (
    <div>
        <ExhibitionContentNavbar/>
      <h1 className="mt-3">Update The Time Table of Exhibition</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-3">
            <form onSubmit={handleSubmit}>
              {/* Show spinner while data is loading */}
              {loading && (
                <div className="d-flex justify-content-center mb-3">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}

              {rows.map((row) => (
                <div key={row.id} className="">
                  <div className="row g-2 mb-3">
                    <div className="col">
                      <label className="form-label">
                        Timezone<span className="text-danger">*</span>
                      </label>
                      <select
                        name={`timezone-${row.id}`}
                        className="form-control"
                        value={row.timezone}
                        onChange={(e) => handleInputChange(e, row.id, "timezone")}
                        required
                      >
                        <option value="">Select Timezone</option>
                        <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
	<option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
	<option value="-10:00">(GMT -10:00) Hawaii</option>
	<option value="-09:50">(GMT -9:30) Taiohae</option>
	<option value="-09:00">(GMT -9:00) Alaska</option>
	<option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
	<option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
	<option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
	<option value="-05:00">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
	<option value="-04:50">(GMT -4:30) Caracas</option>
	<option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
	<option value="-03:50">(GMT -3:30) Newfoundland</option>
	<option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
	<option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
	<option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</option>
	<option value="+00:00">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
	<option value="+01:00">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
	<option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</option>
	<option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
	<option value="+03:50">(GMT +3:30) Tehran</option>
	<option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
	<option value="+04:50">(GMT +4:30) Kabul</option>
	<option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
	<option value="+05:50">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
	<option value="+05:75">(GMT +5:45) Kathmandu, Pokhara</option>
	<option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</option>
	<option value="+06:50">(GMT +6:30) Yangon, Mandalay</option>
	<option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
	<option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
	<option value="+08:75">(GMT +8:45) Eucla</option>
	<option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
	<option value="+09:50">(GMT +9:30) Adelaide, Darwin</option>
	<option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
	<option value="+10:50">(GMT +10:30) Lord Howe Island</option>
	<option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
	<option value="+11:50">(GMT +11:30) Norfolk Island</option>
	<option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
	<option value="+12:75">(GMT +12:45) Chatham Islands</option>
	<option value="+13:00">(GMT +13:00) Apia, Nukualofa</option>
	<option value="+14:00">(GMT +14:00) Line Islands, Tokelau</option>
                      </select>
                    </div>

                    <div className="col">
                      <label className="form-label">
                        Select Date<span className="text-danger">*</span>
                      </label>
                      <select
                        name={`date-${row.id}`}
                        className="form-control"
                        value={row.selected_date}
                        onChange={(e) => handleInputChange(e, row.id, "selected_date")}
                        required
                      >
                        <option value="">Select Date</option>
                        {dateRange.map((date, index) => (
                          <option key={index} value={date}>
                            {date}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col">
                      <label className="form-label">
                        Start Time<span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name={`startTime-${row.id}`}
                        className="form-control"
                        value={row.start_time}
                        onChange={(e) => handleInputChange(e, row.id, "start_time")}
                        required
                      />
                    </div>

                    <div className="col">
                      <label className="form-label">
                        End Time<span className="text-danger">*</span>
                      </label>
                      <input
                        type="time"
                        name={`endTime-${row.id}`}
                        className="form-control"
                        value={row.end_time}
                        onChange={(e) => handleInputChange(e, row.id, "end_time")}
                        required
                      />
                    </div>

                    <div className="col text-end colmxwd">
                      <label className="form-label">Action</label>
                      <div
                        type="button"
                        className="px-3"
                        onClick={() => handleDeleteRow(row.id)}
                      >
                        <Trash2 size={20} />
                      </div>
                    </div>
                    <hr className="mt-2" />
                  </div>
                </div>
              ))}

              <div className="col-md-12 mt-3 text-end">
                <div className="text-start">
                  <span onClick={handleAddRow} className="addrowdiv">
                    <PlusCircle className="text-dark" size={20} /> Add Row
                  </span>
                </div>
                {/* <button type="submit" className="btnn">
                Submit
                </button> */}
              </div>



              {/* Submit Button */}
                            <div className="col-md-12 mt-5 d-flex justify-content-between align-items-center">
                              <span className="">
                                <Link to={`/exhibition-dashboard/${exhibitionId}/additional-details`} className="btnn mybtn">
                                <ArrowLeft className="ic text-white" />
                                Back</Link>
                                </span>
                              <button type="submit" className="btnn mt-0">
                                Next <ArrowRight className="ic text-white" />
                              </button>
                            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionTimeDetails;
