// src/component/ExhibitionComp/ExhibitionStats.js
import React from "react";
import { Heart, UserPlus, Edit } from "react-feather";
import { Link } from "react-router-dom";
import PageView from "../ExhibitionComponent/PageView";
import LeadGenerated from "../ExhibitionComponent/LeadGenerated";
import ExhibitionReportNavbar from "../utils/ExhibitionReportNavbar";
import { useExhibition } from "../../context/ExhibitionContext";
import GeoMap from "../ExhibitionComponent/GeoMap";

const GeoAnalytics = () => {
  const { exhibitionId } = useExhibition();
  return (
    <div>
      <ExhibitionReportNavbar />

      <div className="text-start">
        <h1 className="mt-3">Geo Analytics Report</h1>
        {/* <p className="mb-2">Welcome to the Exhibition Dashboard.</p> */}
      </div>

      <div className="">
        <div className="row g-3 mb-3">
          <div className="col-md-12">
            <div className=" crd1">
              <GeoMap exhibitionId={exhibitionId} /> 
            </div>
          </div>

          {/* <div className="col-md-12">
            <div className=" crd1"> 
              <LeadGenerated exhibitionId={exhibitionId} />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default GeoAnalytics;
