import React from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { Search, LogOut as UsersIcon } from "react-feather"; 
import { useExhibition } from "../../context/ExhibitionContext"; 

const ExhibitionCustomFormNavbar = () => {
    const { exhibitionId } = useExhibition();
    const location = useLocation(); // Get the current location

    // Function to check if the current path is the same as the given path
    const isActive = (path) => location.pathname === path;

    return (
        <div className="fixedtp30 mt-3">
            {/* Search Box */}
            <div className="chaintop">
                <span>
                    <Link 
                        to={`/exhibition-dashboard/${exhibitionId}/create-deligate-custom-form`} 
                        className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/create-deligate-custom-form`) ? "active1" : ""}`}
                    >
                        Delegate Custom Form
                    </Link>
                </span>
                <span>
                    <Link 
                        to={`/exhibition-dashboard/${exhibitionId}/create-speaker-custom-form`} 
                        className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/create-speaker-custom-form`) ? "active1" : ""}`}
                    >
                        Speaker Custom Form
                    </Link>
                </span>
                <span>
                    <Link 
                        to={`/exhibition-dashboard/${exhibitionId}/create-sponser-custom-form`} 
                        className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/create-sponser-custom-form`) ? "active1" : ""}`}
                    >
                        Sponsor Custom Form
                    </Link>
                </span>
                <span>
                    <Link 
                        to={`/exhibition-dashboard/${exhibitionId}/create-visitor-custom-form`} 
                        className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/create-visitor-custom-form`) ? "active1" : ""}`}
                    >
                        Visitor Custom Form
                    </Link>
                </span>
                <span>
                    <Link 
                        to={`/exhibition-dashboard/${exhibitionId}/create-exhibitor-custom-form`} 
                        className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/create-exhibitor-custom-form`) ? "active1" : ""}`}
                    >
                        Exhibitor Custom Form
                    </Link>
                </span>
            </div>
        </div>
    );
};

export default ExhibitionCustomFormNavbar;
