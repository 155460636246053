import React, { useState, useEffect } from "react";
import { useExhibition } from "../../context/ExhibitionContext"; // Import the context hook
import { useNavigate } from "react-router-dom"; // Import navigate hook
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar"; // Import Navbar
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone"; // Import Dropzone for drag-and-drop
import axios from "axios"; // Import axios
import { baseurl, imageUrl } from "../../component/Baseurl";
import { Link } from "react-router-dom";
import { ArrowRight, ArrowLeft } from "react-feather";

const ExhibitionImageDetails = () => {
  // Destructure the context to get the exhibition ID
  const { exhibitionId } = useExhibition();
  const navigate = useNavigate(); // For navigation after submission

  const org_id = localStorage.getItem("orgId");

  const [exhibitionBanner, setExhibitionBanner] = useState(null);
  const [exhibitionFullBanner, setExhibitionFullBanner] = useState(null);
  const [fetchedBanner, setFetchedBanner] = useState(null); // State for fetched banner
  const [fetchedFullBanner, setFetchedFullBanner] = useState(null); // State for fetched full banner

  // Fetch event file details on component mount
  useEffect(() => {
    const fetchEventFileDetails = async () => {
      try {
        // Send the exhibitionId in the URL path
        const response = await axios.get(
          `${baseurl}/getEventFileDetails/${exhibitionId}`
        );

        // Log the response to the console
        console.log("Response from API:", response);

        if (response.data) {
          const { eventbanner, eventfullbanner } = response.data;
          setFetchedBanner(eventbanner); // Set fetched banner
          setFetchedFullBanner(eventfullbanner); // Set fetched full banner
        }
      } catch (error) {
        // console.error("Error fetching event file details:", error);
        // toast.error("Failed to fetch file details.");
      }
    };

    if (exhibitionId) {
      fetchEventFileDetails();
    }
  }, [exhibitionId]);

  // Handle file drop for exhibition banners
  const onDropExhibitionBanner = (acceptedFiles) => {
    // Check if there are files in the acceptedFiles array
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0]; // Get the first file (in case of multiple files)
      if (file.size > 500000) {
        // Show toastr error message if file size exceeds 500KB
        toast.error("Exhibition Banner file size exceeds 500KB!");
      } else {
        setExhibitionBanner(file); // Set the file if it's within the size limit
      }
    }
  };

  // Handle file drop for exhibition full banners
  const onDropExhibitionFullBanner = (acceptedFiles) => {
    // Check if there are files in the acceptedFiles array
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0]; // Get the first file (in case of multiple files)
      if (file.size > 500000) {
        // Show toastr error message if file size exceeds 500KB
        toast.error("Exhibition Full Banner file size exceeds 500KB!");
      } else {
        setExhibitionFullBanner(file); // Set the file if it's within the size limit
      }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure at least one banner is uploaded before proceeding
    if (!exhibitionBanner && !exhibitionFullBanner) {
      toast.error("Please upload at least one banner.");
      return;
    }

    // Create FormData to hold the files and other data
    const formData = new FormData();
    if (exhibitionBanner) formData.append("exhibitionBanner", exhibitionBanner);
    if (exhibitionFullBanner)
      formData.append("exhibitionFullBanner", exhibitionFullBanner);
    formData.append("exhibitionId", exhibitionId); // Ensure you have this variable from your form/state
    formData.append("orgId", org_id); // Same here for org_id

    try {
      // Make the API call to upload banners
      const response = await axios.post(
        `${baseurl}/insertEventFileDetails`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure correct content type for file uploads
          },
        }
      );

      // Handle the response from the server
      if (response.status === 200) {
        // Show success message using toast
        // toast.success("Banners uploaded successfully!");

        // Navigate to the next page after successful upload
        navigate(`/exhibition-dashboard/${exhibitionId}/exhibitor-details`);
      } else {
        toast.error("Failed to upload banners.");
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error during API call:", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  // Drag-and-drop configuration for banners
  const {
    getRootProps: getRootPropsExhibitionBanner,
    getInputProps: getInputPropsExhibitionBanner,
  } = useDropzone({
    onDrop: onDropExhibitionBanner,
    accept: "image/*",
    maxSize: 500000, // Limit the file size to 500KB
  });

  const {
    getRootProps: getRootPropsExhibitionFullBanner,
    getInputProps: getInputPropsExhibitionFullBanner,
  } = useDropzone({
    onDrop: onDropExhibitionFullBanner,
    accept: "image/*",
    maxSize: 500000, // Limit the file size to 500KB
  });

  return (
    <div>
      <ExhibitionContentNavbar />
      <h1 className="mt-3">Upload Exhibition Banners</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-4 shadow-lg rounded-lg">
            <form onSubmit={handleSubmit}>
              <div className="row g-3">
                {/* Exhibition Banner Upload */}
                <div className="col-md-6">
                  <label className="form-label">
                    Exhibition Banner{" "}
                    <small className="udrl">
                      [Max Size : 500kb, File Type : JPEG, JPG, WEBP, PNG ONLY]
                    </small>
                    <span className="text-danger">*</span>
                  </label>
                  <div
                    {...getRootPropsExhibitionBanner()}
                    className="dropzone-container border border-primary p-3 rounded-lg"
                  >
                    <input {...getInputPropsExhibitionBanner()} />
                    <p className="text-center text-muted">
                      Drag & drop your banner here, or click to select
                    </p>
                    {exhibitionBanner && (
                      <div className="mt-2 text-center">
                        <img
                          src={URL.createObjectURL(exhibitionBanner)}
                          alt="Exhibition Banner"
                          style={{ width: "200px", borderRadius: "8px" }}
                        />
                      </div>
                    )}
                    {fetchedBanner && !exhibitionBanner && (
                      <div className="mt-2 text-center">
                        <img
                          src={`${imageUrl}eventFiles/${fetchedBanner}`}
                          alt="Fetched Exhibition Banner"
                          style={{ width: "200px", borderRadius: "8px" }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* Exhibition Full Banner Upload */}
                <div className="col-md-12">
                  <label className="form-label">
                    Exhibition Full Banner{" "}
                    <small className="udrl">
                      [Max Size : 500kb, File Type : JPEG, JPG, WEBP, PNG ONLY]
                    </small>
                    <span className="text-danger">*</span>
                  </label>
                  <div
                    {...getRootPropsExhibitionFullBanner()}
                    className="dropzone-container border border-primary p-3 rounded-lg"
                  >
                    <input {...getInputPropsExhibitionFullBanner()} />
                    <p className="text-center text-muted">
                      Drag & drop your full banner here, or click to select
                    </p>
                    {exhibitionFullBanner && (
                      <div className="mt-2 text-center">
                        <img
                          src={URL.createObjectURL(exhibitionFullBanner)}
                          alt="Exhibition Full Banner"
                          style={{ width: "200px", borderRadius: "8px" }}
                        />
                      </div>
                    )}
                    {fetchedFullBanner && !exhibitionFullBanner && (
                      <div className="mt-2 text-center">
                        <img
                          src={`${imageUrl}eventFiles/${fetchedFullBanner}`}
                          alt="Fetched Full Banner"
                          style={{ width: "200px", borderRadius: "8px" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <div className="col-md-12 mt-5 d-flex justify-content-between align-items-center">
                <span className="">
                  <Link
                    to={`/exhibition-dashboard/${exhibitionId}/time-details`}
                    className="btnn mybtn"
                  >
                    <ArrowLeft className="ic text-white" />
                    Back
                  </Link>
                </span>
                {/* <button type="submit" className="btnn mt-0">
                  Next <ArrowRight className="ic text-white" />
                </button> */}

                {!fetchedFullBanner || !fetchedBanner ? (
                  <button type="submit" className="btnn mt-0">
                    Next <ArrowRight className="ic text-white" />
                  </button>
                ) : (
                  <Link
                    to={`/exhibition-dashboard/${exhibitionId}/exhibitor-details`}
                    className="btnn mybtn"
                  >
                    Next <ArrowRight className="ic text-white" />
                  </Link>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer /> {/* Toast container for notifications */}
    </div>
  );
};

export default ExhibitionImageDetails;
