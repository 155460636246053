// src/component/ExhibitionComp/ExhibitionStats.js
import React from 'react';
import { Heart, UserPlus, Edit } from "react-feather";
import { Link } from 'react-router-dom';
import VisitorChart from '../ExhibitionComponent/VisitorChart';

const ExhibitionStats = () => {
  return (
    <div>

 

        <div className="mt-3">
          

        <div class="d-flex justify-content-between"><h1>Create Custom Form For Delegate</h1></div>

          <div className="row g-3 mb-3">
            <div className="col-md-8">
              <div className=" crd1">
              <div className="hd-1">Visitors Count</div>
               <VisitorChart/>
              </div>
            </div>

            <div className="col-md-4">
            <div className="crd1 px-3 mb-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <UserPlus className="ic2 cl4" size={45} />
                  </div>
                  <div>
                    <div className="txtd1">VISITORS</div>
                    <div className="txtd2 text-end">89</div>
                  </div>
                </div>
              </div>

              <div className="crd1 px-3  mb-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <UserPlus className="ic2 cl4" size={45} />
                  </div>
                  <div>
                    <div className="txtd1">SPEAKERS</div>
                    <div className="txtd2 text-end">89</div>
                  </div>
                </div>
              </div>

              <div className="crd1 px-3  mb-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <UserPlus className="ic2 cl4" size={45} />
                  </div>
                  <div>
                    <div className="txtd1">SPONSORS</div>
                    <div className="txtd2 text-end">89</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="crd1">
              
              </div>
            </div>

            <div className="col-md-8">
              <div className="crd1">
             
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-md-9">
              <div className="crd1">
                <div className="hd-1">System Users List</div>

                <hr />

                {/* Table Headers */}
                <div className="row text-start tblrowhd m-0">
                  <div className="col">Name</div>
                  <div className="col">Email</div>
                  <div className="col">Department</div>
                  <div className="col">Designation</div>
                  <div className="col">User Role</div>

                  <div className="col text-end">Action</div>
                </div>
                <hr />

                <div className="row user-table-data tblrowtd text-start m-0">
                  <div className="col">Kunal Gupta</div>
                  <div className="col">test@email.com</div>
                  <div className="col">teastea</div>
                  <div className="col">Test Data</div>
                  <div className="col">test gags</div>
                  <div className="col text-end">
                    <Link to="/" className="editbg">
                      <Edit className="ic1" />
                    </Link>
                  </div>
                </div>

              
 
               
              </div>
            </div>
            <div className="col-md-3">
              <div className="crd1">
                <div className="hd-1">System Users List</div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default ExhibitionStats;
