import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate hook
import axios from "axios";
import { baseurl } from "../component/Baseurl"; // Import baseurl

const OrgLogin = () => {
  const [formData, setFormData] = useState({
    org_email: "",
    otp: "", // Add OTP state to store OTP input
  });

  const [errorMessages, setErrorMessages] = useState({
    org_email: "",
    general: "",
  });

  const [loading, setLoading] = useState(false); // Loading state
  const [otpSent, setOtpSent] = useState(false); // State to track OTP sent
  const [otpInput, setOtpInput] = useState(["", "", "", "", "", ""]); // State for OTP input (6 digits)
  const [emailDisabled, setEmailDisabled] = useState(false); // State for disabling email input after login
  const navigate = useNavigate(); // Hook for navigation

  // Handle form input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrorMessages({ ...errorMessages, [id]: "" }); // Clear specific error when user starts typing
  };

  // Handle OTP input change (for progressive input)
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    const updatedOtpInput = [...otpInput];
    updatedOtpInput[index] = value;

    setOtpInput(updatedOtpInput);
    
    // Automatically focus on the next input field
    if (value && index < otpInput.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  // Handle form submit for login
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Log the formData to the console to check the data being sent
    console.log("Data being sent:", formData);

    // Set loading to true and simulate the login process
    setLoading(true);

    try {
      // Make an API request to login the organization
      const response = await axios.post(`${baseurl}login-organizations`, { org_email: formData.org_email });

      // Log the response from the API
      console.log("API response:", response);

      if (response.status === 200) {
        // Login was successful, set success message and show OTP inputs
        setOtpSent(true); // Show OTP input fields
        setEmailDisabled(true); // Disable the email input after successful login
      }

    } catch (error) {
      console.error("There was an error!", error);

      // Set error messages based on response status code
      if (error.response) {
        const statusCode = error.response.status;

        switch (statusCode) {
          case 400:
            setErrorMessages({ ...errorMessages, general: "Email not found. Please check the email." });
            break;
          case 500:
            setErrorMessages({ ...errorMessages, general: "Something went wrong. Please try again later." });
            break;
          case 401:
            setErrorMessages({ ...errorMessages, general: "Invalid credentials. Please try again." });
            break;
          default:
            setErrorMessages({ ...errorMessages, general: "An unexpected error occurred. Please try again later." });
            break;
        }
      }
    } finally {
      setLoading(false); // Stop the loading spinner after API response
    }
  };

  // Handle OTP submission (for verification purposes)
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    
    // Join the OTP input fields to form the complete OTP string
    const otp = otpInput.join('');
    
    // Prepare the form data to send in the API request
    const data = {
      org_email: formData.org_email,  // The email that was used in the login form
      otp: otp,                       // The OTP entered by the user
    };
    
    try {
      // Send a POST request to verify the OTP
      const response = await axios.post(`${baseurl}checkloginotp`, data);
    
      // Log the API response to check the result
      console.log("OTP verification response:", response);
    
      if (response.status === 200) {
        // OTP is verified successfully
        console.log('OTP verified successfully');
        
        // Store the organization ID and email in localStorage
        localStorage.setItem('orgId', response.data.id);  // Save organization ID
        localStorage.setItem('orgEmail', response.data.org_email);  // Save organization email
  
         
        console.log("Stored orgId in localStorage:", localStorage.getItem("orgId"));
        console.log("Stored orgEmail in localStorage:", localStorage.getItem("orgEmail"));

        // Navigate to the dashboard (if necessary)
        navigate('/dashboard');  
      } else {
        // Handle the case where OTP verification failed
        setErrorMessages({ ...errorMessages, general: "Invalid OTP. Please try again." });
      }
    } catch (error) {
      // Handle any errors during the API request
      console.error("Error during OTP verification:", error);
      setErrorMessages({ ...errorMessages, general: "Failed to verify OTP. Please try again later." });
    }
  };
  

  // Resend OTP logic
  const handleResendOtp = async () => {
    setLoading(true);
    console.log("Resending OTP...");

    // Make API request to resend OTP
    try {
      const response = await axios.post(`${baseurl}resend-otp`, { org_email: formData.org_email });
      if (response.status === 200) {
        console.log("OTP resent successfully.");
        setErrorMessages({ ...errorMessages, general: "OTP has been resent to your email." });
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      setErrorMessages({ ...errorMessages, general: "Failed to resend OTP. Please try again later." });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="bg-login d-flex align-items-center">
      <div className="container">
        <div className="row justify-content-center minht align-items-center">
          <div className="col-md-8">
            <div className="my-card p-5">
              <div className="text-center">
                <h3 className="header-title mb-4">HRNEXA Organization Login</h3>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3 className="header-title">Login to Your Account</h3>
                </div>
                <div>
                  <span className="header-subtitle">
                    Don't have an account?{" "}
                    <Link to="/signup">
                      Register As Organisation
                    </Link>
                  </span>
                </div>
              </div>

              <div className="login mt-3">
                <form onSubmit={handleSubmit}>
                  <label htmlFor="org_email">
                    <input
                      type="email"
                      id="org_email"
                      placeholder="Enter Organization Email"
                      required
                      value={formData.org_email}
                      onChange={handleChange}
                      disabled={emailDisabled} // Disable email input after login
                    />
                    <span>Organization Email</span>
                  </label>
                  {errorMessages.org_email && (
                    <div className="error-message errormsg">{errorMessages.org_email}</div>
                  )}

                  {errorMessages.general && (
                    <div className="error-message errormsg">{errorMessages.general}</div>
                  )}

                  {!otpSent && (
                    <div className="text-center">
                      <button type="submit" className="btnn" disabled={loading}>
                        {loading ? (
                          <div>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Logging In...
                          </div>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                  )}
                </form>

                {/* OTP input fields appear if OTP is sent */}
                {otpSent && (
                  <form onSubmit={handleOtpSubmit} className="mt-4">
                    <div className="otp-inputs">
                      {[...Array(6)].map((_, index) => (
                        <input
                          key={index}
                          id={`otp-${index}`}
                          type="text"
                          maxLength="1"
                          value={otpInput[index]}
                          onChange={(e) => handleOtpChange(e, index)}
                          className="otp-input"
                        />
                      ))}
                    </div>
                    <button type="submit" className="btnn">Verify OTP</button>
                    <button type="button" className="btnn mt-2" onClick={handleResendOtp}>Resend OTP</button>
                  </form>
                )}

                <hr className="mt-4 mb-4" />
                <p className="text-muted ptxt">
                  We’re committed to your privacy. Kredily uses the information
                  you provide to us to contact you about our relevant content.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrgLogin;
