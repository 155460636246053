import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseurl } from "../Baseurl";
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";

const EditExhibitor = ({ exhibitor, showModal, closeModal, refreshExhibitors }) => {
  const [formData, setFormData] = useState({
    name: exhibitor?.name || '',
    designation: exhibitor?.designation || '',
    company: exhibitor?.company || '',
    email: exhibitor?.email || '',
    phone: exhibitor?.phone || '',
    booth_no: exhibitor?.booth_no || '',
  });

  useEffect(() => {
    if (exhibitor) {
      setFormData({
        name: exhibitor.name,
        designation: exhibitor.designation,
        company: exhibitor.company,
        email: exhibitor.email,
        phone: exhibitor.phone || '',
        booth_no: exhibitor.booth_no || '',
      });
    }
  }, [exhibitor]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(`${baseurl}/updateExhibitor/${exhibitor.id}`, formData);
      if (response.data.success) {
        refreshExhibitors(); // Refresh the exhibitor list
        closeModal(); // Close modal after successful update
      } else {
        console.error("Failed to update exhibitor");
      }
    } catch (error) {
      console.error("Error updating exhibitor:", error);
    }
  };

  if (!showModal) return null; // Do not render if modal is not visible

  return (


    <div>
    <ExhibitionContentNavbar/>
    <h1 className="mt-3">Additional Details</h1>
    <div className="row">
      <div className="col-md-12">
        <div className="crd1 p-3">
        <form>
          <label>Name:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} />

          <label>Designation:</label>
          <input type="text" name="designation" value={formData.designation} onChange={handleChange} />

          <label>Company:</label>
          <input type="text" name="company" value={formData.company} onChange={handleChange} />

          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} />

          <label>Phone:</label>
          <input type="text" name="phone" value={formData.phone} onChange={handleChange} />

          <label>Booth No:</label>
          <input type="text" name="booth_no" value={formData.booth_no} onChange={handleChange} />

          <button type="button" onClick={handleUpdate}>Update</button>
        </form>
        </div>
      </div>
    </div>
  </div>


     
  );
};

export default EditExhibitor;
