import React, { useState, useEffect } from "react";
import LogoutBtn from "../component/LogoutBtn";
import { baseurl } from "../component/Baseurl"; // Import baseurl
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditUser = () => {
  const [branches, setBranches] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    role: "",
    selectedBranch: "",
    designation: "",
    department: "",
    status: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch Branches
    const fetchBranches = async () => {
      try {
        const orgId = localStorage.getItem("orgId");
        const response = await axios.get(`${baseurl}branches/${orgId}`);
        if (Array.isArray(response.data.branches)) {
          setBranches(response.data.branches);
        } else {
          toast.error("Branches data is not an array.");
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
        toast.error("Error fetching branches.");
      }
    };
    fetchBranches();

    // Fetch Designations
    const fetchDesignations = async () => {
      try {
        const response = await axios.get(`${baseurl}getAllDesignations`);
        if (Array.isArray(response.data)) {
          setDesignations(response.data);
        } else {
          toast.error("Designations data is not an array.");
        }
      } catch (error) {
        console.error("Error fetching designations:", error);
        toast.error("Error fetching designations.");
      }
    };
    fetchDesignations();

    // Fetch User Data by ID
    const fetchUserData = async () => {
      try {
        const decodedId = atob(id); // Decode the Base64 ID
        const response = await axios.get(`${baseurl}getuserbyid/${decodedId}`);
        if (response.data) {
          setUserData(response.data); // Populate the form with user data
        } else {
          toast.error("User not found.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Error fetching user data.");
      }
    };
    fetchUserData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value, // Update the state with the updated value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const decodedId = atob(id); // Decode the Base64 ID
      console.log("Decoded ID:", decodedId); // Log the decoded ID
      console.log("Submitting data:", userData); // Log the userData object to see the sent data
  
      // Send the user form data (userData) directly
      const response = await axios.post(
        `${baseurl}updateUser/${decodedId}`,
        {
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          phone: userData.phone,
          role: userData.role,
          selectedBranch: userData.selectedBranch,
          designation: userData.designation,
          department: userData.department,
          status: userData.status,
          account_password: userData.account_password || "", // Include account password if provided
        }
      );
  
      if (response.status === 200) {
        toast.success("User updated successfully.");
        navigate(`/users/${decodedId}`); // Navigate to the user details page after successful update
      }
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Error updating user.");
    }
  };
  

  return (
    <div>
      <div className="d-flex tp-at align-items-center justify-content-between sticky-top">
        <div className="tp-h-tl">System Users / Update System User</div>
        <div>
          <LogoutBtn />
        </div>
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="crd">
            <div className="hd-1">Update User</div>
            <hr />
            <div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="first_name" className="w-100">
                      <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={userData.first_name}
                        onChange={handleChange}
                        placeholder="First Name"
                        required
                      />
                      <span>First Name</span>
                    </label>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="last_name" className="w-100">
                      <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={userData.last_name}
                        onChange={handleChange}
                        placeholder="Last Name"
                        required
                      />
                      <span>Last Name</span>
                    </label>
                  </div>

                  <div className="col-md-3">
                    <label htmlFor="country_code" className="w-100">
                      <select
                        id="country_code"
                        name="country_code"
                        defaultValue="+91" // Assuming India country code as default
                        required
                      >
                        <option value="+91">India (+91) </option>
                      </select>
                    </label>
                  </div>

                  <div className="col-md-9">
                    <label htmlFor="phone" className="w-100">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={userData.phone}
                        onChange={handleChange}
                        placeholder="Phone"
                        required
                      />
                      <span>Phone</span>
                    </label>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="email" className="w-100">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                        placeholder="Email"
                        required
                      />
                      <span>Email</span>
                    </label>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="account_password" className="w-100">
                      <input
                        type="password"
                        id="account_password"
                        name="account_password"
                        value={userData.account_password || ""}
                        onChange={handleChange}
                        placeholder="Account Password"
                      />
                      <span>Account Password</span>
                    </label>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="role" className="w-100">
                      <select
                        id="role"
                        name="role"
                        value={userData.role_id || ""}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Role</option>
                        <option value="1">HR Manager</option>
                        <option value="2">Admin</option>
                        <option value="3">Executive / Employee</option>
                        <option value="4">Director</option>
                      </select>
                    </label>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="selectedBranch" className="w-100">
                      <select
                        id="selectedBranch"
                        name="selectedBranch"
                        value={userData.selectedBranch}
                        onChange={handleChange}
                        required
                      >
                        {branches.map((branch) => (
                          <option key={branch.id} value={branch.id}>
                            {branch.branch_name} [{branch.branch_city},{" "}
                            {branch.branch_state}]
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="designation" className="w-100">
                      <select
                        id="designation"
                        name="designation"
                        value={userData.designation}
                        onChange={handleChange}
                        required
                      >
                        {designations.map((designation) => (
                          <option
                            key={designation.designation}
                            value={designation.designation}
                          >
                            {designation.designation}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="department" className="w-100">
                      <select
                        id="department"
                        name="department"
                        value={userData.department}
                        onChange={handleChange}
                        required
                      >
                        <option value="Human Resources (HR)">
                          Human Resources (HR)
                        </option>
                        <option value="Finance">Finance</option>
                        <option value="Sales and Marketing">
                          Sales and Marketing
                        </option>
                      </select>
                    </label>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="status" className="w-100">
                      <select
                        id="status"
                        name="status"
                        value={userData.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </label>
                  </div>

                  <div className="col-md-12 mt-3 text-center">
                    <button type="submit" className="btn1">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default EditUser;
