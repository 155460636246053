import React, { useEffect, useState } from "react";
import ExhibitionLeadsNavbar from "../utils/ExhibitionLeadsNavbar";
import { useExhibition } from "../../context/ExhibitionContext";
import CustomForm from "./CustomForm";
import { baseurl } from "../Baseurl";
import axios from "axios";
import MyCustomForm from "../ExhibitionComponent/MyCustomForm";
import ExhibitionCustomFormNavbar from "../utils/ExhibitionCustomFormNavbar";

const CustomDeligateForm = () => {
    const { exhibitionId } = useExhibition();
    const formFor = "Delegate";

    const [formDetails, setFormDetails] = useState(null); // State to hold the API response
    const [isLoading, setIsLoading] = useState(true); // State to track loading status

    console.log("exhibitionId", exhibitionId);

    useEffect(() => {
        // Define the API call
        const fetchFormDetails = async () => {
            try {
                const response = await axios.get(
                    `${baseurl}/getCustomFormsByEvent/${exhibitionId}/${formFor}`
                );
                console.log("API Response:", response.data);
                setFormDetails(response.data?.[0] || null); // Store the first form if it exists
            } catch (error) {
                console.error("Error fetching form details:", error);
                setFormDetails(null); // Reset form details on error
            } finally {
                setIsLoading(false); // Mark loading as complete
            }
        };

        // Call the API if exhibitionId is available
        if (exhibitionId) {
            fetchFormDetails();
        }
    }, [exhibitionId, formFor]);

    if (isLoading) {
        return <div>Loading...</div>; // Show a loading message while fetching data
    }

    return (
        <div>
<ExhibitionCustomFormNavbar/>

            {/* Render MyCustomForm if formDetails has data, otherwise render CustomForm */}
            {formDetails ? (
                <MyCustomForm formId={formDetails.form_id} />
            ) : (
                <CustomForm eventId={exhibitionId} formFor={formFor} />
            )}
        </div>
    );
};

export default CustomDeligateForm;
