import React from "react";
import { Link } from "react-router-dom";
import { Search, LogOut as UsersIcon } from "react-feather"; 

const Topbar = () => {
  return (
    <div className="d-flex tp-at align-items-center justify-content-between sticky-top">
      {/* Search Box */}
      <div className="d-flex align-items-center search-box">
        <Search className="search-icon" />
        <input
          type="text"
          className="form-control search-input mt-0"
          placeholder="Search..."
        />
      </div>
      
      {/* Logout Button */}
      <div>
        <Link className="logoutbtn d-flex align-items-center" to="/logout">
          <UsersIcon className="ic" />
          <span>Logout</span>
        </Link>
      </div>
    </div>
  );
};

export default Topbar;
