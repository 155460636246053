import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { useExhibition } from "../../context/ExhibitionContext"; // Import ExhibitionContext
import axios from "axios";
import { baseurl } from "../../component/Baseurl"; // Import baseurl

const ExhibitionLeadsNavbar = () => {
    const { exhibitionId } = useExhibition(); // Get exhibitionId from context
    const location = useLocation(); // Get the current location
    const [formData, setFormData] = useState({}); // State to hold the API response

    // Function to check if the current path is the same as the given path
    const isActive = (path) => location.pathname === path;

    // Fetch data from the API when the component mounts
    useEffect(() => {
        const fetchFormData = async () => {
            try {
                const response = await axios.get(`${baseurl}fetchFormDataByEventIdFormfor/${exhibitionId}`);
                if (response.data.success) {
                    setFormData(response.data.data); // Set the response data to state
                }
            } catch (error) {
                console.error("Error fetching form data:", error);
            }
        };

        fetchFormData();
    }, [exhibitionId]);


    
    return (
        <div className="fixedtp30 mt-3">
            {/* Navigation Links based on fetched data */}
            <div className="chaintop">
                {formData.delegateid && (
                    <span>
                        <Link 
                            to={`/exhibition-dashboard/${exhibitionId}/delegate-leads`} 
                            className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/delegate-leads`) ? "active1" : ""}`}
                        >
                            Delegate Leads
                        </Link>
                    </span>
                )}

                {formData.speakerid && (
                    <span>
                        <Link 
                            to={`/exhibition-dashboard/${exhibitionId}/speaker-leads`} 
                            className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/speaker-leads`) ? "active1" : ""}`}
                        >
                            Speaker Leads
                        </Link>
                    </span>
                )}

                {formData.sponsorid && (
                    <span>
                        <Link 
                            to={`/exhibition-dashboard/${exhibitionId}/sponser-leads`} 
                            className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/sponser-leads`) ? "active1" : ""}`}
                        >
                            Sponsor Leads
                        </Link>
                    </span>
                )}

                {formData.exhibitorid && (
                    <span>
                        <Link 
                            to={`/exhibition-dashboard/${exhibitionId}/exhibitor-leads`} 
                            className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/exhibitor-leads`) ? "active1" : ""}`}
                        >
                            Exhibitor Leads
                        </Link>
                    </span>
                )}

                {formData.visitorid && (
                    <span>
                        <Link 
                            to={`/exhibition-dashboard/${exhibitionId}/visitor-leads`} 
                            className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/visitor-leads`) ? "active1" : ""}`}
                        >
                            Visitor Leads
                        </Link>
                    </span>
                )}
            </div>
        </div>
    );
};

export default ExhibitionLeadsNavbar;
