import React, { useState, useEffect } from "react";
import { baseurl } from "../../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link } from "react-router-dom";
import { Edit, Trash2, CornerUpRight, Star } from "react-feather";
import { useExhibition } from "../../context/ExhibitionContext"; 
import defaultprofile from "../img/profile.webp";
import ExhibitionCommunicationNavbar from "../utils/ExhibitionCommunicationNavbar";

const ExhibitionRatingReview = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const [showReplyBox, setShowReplyBox] = useState(false); // State for reply box visibility

  const { exhibitionId } = useExhibition();

  // Sample rating data
  const ratingsData = {
    5: 50, // 50% of ratings are 5
    4: 25,
    3: 15,
    2: 7,
    1: 3,
  };

  // Decoded labels for keys
  const ratingLabels = {
    5: "Excellent",
    4: "Very Good",
    3: "Good",
    2: "Average",
    1: "Poor",
  };

  // Function to toggle reply box
  const ShowReplyBox = () => {
    setShowReplyBox((prev) => !prev);
  };

  return (
    <div>
      <ExhibitionCommunicationNavbar/>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h1 className="mt-3">Ratings and Reviews</h1>
          </div>
        </div>

        <div className="row">
          <div className="crd py-4">
            <div className="row g-2 mb-3">
              {/* Left Overall Rating */}
              <div className="col-md-2 text-center">
                <div className="ratingcls mb-0 fw-bold display-3">4.5</div>
                <div className="text-center mb-2">
                  {[...Array(5)].map((_, index) => (
                    <span key={index} className="rating-star text-warning">
                      <Star size={20} />
                    </span>
                  ))}
                </div>
                <div>
                  <small>4.57M reviews</small>
                </div>
              </div>

              {/* Right Rating Distribution */}
              <div className="col-md-6">
                {Object.entries(ratingsData).map(([key, value]) => (
                  <div
                    className="rating-row d-flex align-items-center mb-2"
                    key={key}
                  >
                    <span className="fw-bold" style={{ minWidth: "100px" }}>
                      {ratingLabels[key]}
                    </span>
                    <div
                      className="progress w-100 mx-2"
                      style={{ height: "10px" }}
                    >
                      <div
                        className="progress-bar bg-success"
                        style={{ width: `${value}%` }}
                      ></div>
                    </div>
                    <span className="text-muted">{value}%</span>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src={defaultprofile}
                    alt="name here"
                    className="img-fluid profilerate"
                  />{" "}
                </div>
                <div className="nm1"> Ahtesham Ali</div>
              </div>

              <div className="text-muted mb-2">
                <span>
                  <Star size={13} />
                </span>
                <span>
                  <Star size={13} />
                </span>
                <span>
                  <Star size={13} />
                </span>
                <span>
                  <Star size={13} />
                </span>
                <span>
                  <Star size={13} />
                </span>
                <span className="nm2 text-muted">&nbsp;November 24, 2024</span>
              </div>

              <div className="nm3 mb-3">
                Absolutely great, this app has been developed without any due
                consideration and thoughts. the user interface user friendly.
                You have to really for options which should be easily
                accessible. You cannot see the transaction history which tells
                you how you have earned those points. Points are updated very
                late sometimes, the point added are right. meesho has improved
                as an Ecom brand. The app experience is good. The platform
                offers good deals at very affordable prices. I love this app
                very much.
              </div>

              <div className="nm4">
                <span onClick={ShowReplyBox} style={{ cursor: "pointer" }}>
                 Add Reply <CornerUpRight size={14} />
                </span>
                {showReplyBox && (
                  <div>
                    <textarea
                      className="form-control mt-2"
                      placeholder="Write Your Reply Here..."
                      rows="3"
                      name="reply"
                      required
                    ></textarea>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionRatingReview;
