import React, { useEffect, useState } from "react";

const BarChart = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = "https://code.jscharting.com/latest/jscharting.js";
      script.onload = () => setIsLoaded(true);
      script.onerror = () => console.error("Failed to load JSC script");
      document.head.appendChild(script);
    };

    if (!window.JSC) {
      loadScript();
    } else {
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isLoaded && window.JSC) {
      window.JSC.chart("bar-chart-container", {
        type: "column", // Bar chart type
        title_label_text: "Visitors Over Time (Bar Chart)",
        xAxis: {
          label_text: "Time (Days)",
          scale_interval: 1,
        },
        yAxis: {
          label_text: "Number of Visitors",
        },
        series: [
          {
            name: "Visitors",
            defaultPoint_color: "#4CAF50", // Default bar color
            points: [
              { x: 1, y: 50, color: "#435ebe" }, // Custom color for this bar
              { x: 2, y: 75, color: "#435ebe" },
              { x: 3, y: 30, color: "#435ebe" },
              { x: 4, y: 90, color: "#435ebe" },
              { x: 5, y: 65, color: "#435ebe" },
              { x: 6, y: 120, color: "#435ebe" },
              { x: 7, y: 80, color: "#435ebe" },
              { x: 8, y: 67, color: "#435ebe" },
              { x: 9, y: 200, color: "#435ebe" },
            ],
          },
        ],
        legend_visible: false, // Hide legend
        defaultPoint: {
          label_text: "%y", // Display the y-value on top of each bar
        },
        yAxis_scale: {
          range: [0, 220], // Define the scale range if needed
        },
      });
    }
  }, [isLoaded]);

  return <div id="bar-chart-container" style={{ height: "300px", width: "100%" }}></div>;
};

export default BarChart;
