// src/component/ExhibitionComp/ExhibitionSchedule.js
import React from 'react';

const ExhibitionSchedule = () => {
  return (
    <div>
      <h3>Exhibition Schedule</h3>
      <p>Details about the exhibition schedule...</p>
    </div>
  );
};

export default ExhibitionSchedule;
