import React, { useState, useEffect } from "react";
import { useConference } from "../../context/ConferenceContext";
import axios from "axios";
import { baseurl } from "../../component/Baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConferenceContentNavbar from "../utils/ConferenceContentNavbar";
import AddConferenceSession from "../ConferenceComponent/AddConferenceSession";
import Swal from 'sweetalert2'; 
import { Edit, Trash2, UserPlus } from "react-feather";

const ConferenceSessionAgenda = () => {
  const { conferenceId } = useConference();
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [agendaForm, setAgendaForm] = useState({
    sessionId: "",
    startTime: "",
    endTime: "",
    topic: "",
    speaker: "",
  });
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [showModal, setShowModal] = useState(false);    
//   const [toggleState, setToggleState] = useState(false); 

 // Fetch sessions
 const fetchSessions = async () => {
    try {
      const response = await axios.get(`${baseurl}GetSession/${conferenceId}`);
      if (response.data.success) {
        setSessions(response.data.sessions);
      } else {
        toast.error("Failed to fetch sessions. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching sessions:", error);
      toast.error("Error fetching sessions. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // UseEffect to fetch sessions when conferenceId changes
  useEffect(() => {
    if (conferenceId) {
      fetchSessions();
    }
  }, [conferenceId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAgendaForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!agendaForm.sessionId || !agendaForm.startTime || !agendaForm.endTime) {
      toast.error("Please fill all required fields.");
      return;
    }
  
    try {
      const response = await axios.post(`${baseurl}insertAgendaDetails`, agendaForm);
  
      if (response.data.success) {
        console.log("Response Data:", response.data); // Log response text after success
        toast.success("Agenda added successfully.");
        setAgendaForm({
          sessionId: "",
          startTime: "",
          endTime: "",
          topic: "",
          speaker: "",
        });
        fetchSessions();
        setShowModal(false); // Close modal after successful submission
      } else {
        toast.error("Failed to add agenda.");
      }
    } catch (error) {
      console.error("Error adding agenda:", error);
      toast.error("Error adding agenda. Please try again later.");
    }
  };
  

  const handleAddAgendaClick = (sessionId) => {
    setSelectedSessionId(sessionId);
    setAgendaForm((prev) => ({
      ...prev,
      sessionId: sessionId,
    }));
    setShowModal(true); // Open modal when session is selected
  };

  const closeModal = () => setShowModal(false); // A helper function to close modal



  // Function to handle deleting agenda
  const deleteAgenda = async (agendaId) => {
    console.log("Deleting agenda with ID:", agendaId);
  
    // Show SweetAlert2 confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });
  
    if (result.isConfirmed) {
      try {
        // Make a delete request to the API
        const response = await axios.delete(`${baseurl}deleteAgenda/${agendaId}`);
  
        if (response.data.success) {
          toast.success("Agenda deleted successfully.");
          
          // Fetch the updated list of sessions from the server
          const updatedSessionsResponse = await axios.get(`${baseurl}GetSession/${conferenceId}`);
  
          if (updatedSessionsResponse.data.success) {
            setSessions(updatedSessionsResponse.data.sessions); // Update the sessions state
          } else {
            toast.error("Failed to fetch updated sessions.");
          }
        } else {
          toast.error("Failed to delete agenda.");
        }
      } catch (error) {
        console.error("Error deleting agenda:", error);
        toast.error("Error deleting agenda. Please try again later.");
      }
    } else {
      toast.info("Agenda deletion cancelled.");
    }
  };
  


  return (
    <div>
      <ConferenceContentNavbar />
      <AddConferenceSession />
      <div className="row mt-3">
        <div className="col-md-12">
        {loading ? (
  <p>Loading sessions...</p>
) : sessions.length > 0 ? (
  sessions.map((session) => (
    <div className="crd1 mb-3" key={session.id}>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          Session Name:{" "}
          <strong className="text-capitalize">{session.session_name}</strong>
        </div>
        <div>
          <button
            type="button"
            className="btnn mt-0"
            onClick={() => handleAddAgendaClick(session.id)}
          >
            Add Agenda
          </button>
        </div>
      </div>

      <div>
        <table className="table table-bordered mt-3">
          <thead>
            <tr>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Topic</th>
              <th>Speaker</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {session.agenda && session.agenda.length > 0 ? (
              session.agenda.map((agenda, index) => (
                <tr key={index}>
                  <td>{agenda.start_time}</td>
                  <td>{agenda.end_time}</td>
                  <td>{agenda.agenda_topic || "N/A"}</td>
                  <td>{agenda.agenda_speaker || "N/A"}</td>
                  <td>
                    <Trash2
                      className="ic1"
                      onClick={() => deleteAgenda(agenda.agendaId)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No agenda available for this session.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  ))
) : (
  <p>No sessions found for this conference.</p>
)}

        </div>
      </div>

      {/* Modal to Add Agenda */}
      {showModal && (
  <div
    className="modal fade show"
    id="addAgendaModal"
    tabIndex="-1"
    aria-labelledby="addAgendaModalLabel"
    aria-hidden="true"
    // style={{ display: "block" }} 
    style={{ display: showModal ? "block" : "none" }}

  >
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="addAgendaModalLabel">
            Add Agenda for Session {agendaForm.sessionId}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowModal(false)} // Close modal by updating React state
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="hidden"
                name="sessionId"
                className="form-control"
                value={agendaForm.sessionId}
                onChange={handleChange}
                required
                readOnly
              />
            </div>

            <div className="form-group">
              <label htmlFor="startTime">
                Start Time<span className="text-danger">*</span>
              </label>
              <input
                type="time"
                name="startTime"
                className="form-control"
                value={agendaForm.startTime}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="endTime">
                End Time<span className="text-danger">*</span>
              </label>
              <input
                type="time"
                name="endTime"
                className="form-control"
                value={agendaForm.endTime}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="topic">
                Enter Topic<span className="text-danger">*</span>
              </label>
              <textarea
                name="topic"
                className="form-control"
                value={agendaForm.topic}
                onChange={handleChange}
                rows="4"
                placeholder="Enter Topic Here"
                required
              ></textarea>
            </div>

            <div className="form-group">
              <label htmlFor="speaker">
                Enter Speaker<span className="text-danger">*</span>
              </label>
              <textarea
                name="speaker"
                className="form-control"
                value={agendaForm.speaker}
                onChange={handleChange}
                rows="4"
                placeholder="Enter Speaker Here"
                required
              ></textarea>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btnn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
)}


      <ToastContainer />
    </div>
  );
};

export default ConferenceSessionAgenda;