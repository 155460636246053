import React, { useState, useEffect } from "react";
import LogoutBtn from "../component/LogoutBtn";
import { baseurl } from "../component/Baseurl"; // Import baseurl
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom'; 
import { Filter, Home, Edit, LogOut as UsersIcon } from "react-feather"; 
import Topbar from "../component/utils/Topbar";

const Users = () => {
  const [isOpen, setIsOpen] = useState(true); // State to manage accordion visibility
  const [users, setUsers] = useState([]); // State to store user data
  const [filteredUsers, setFilteredUsers] = useState([]); // State for filtered users
  const [error, setError] = useState(null); // State to handle errors
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" }); // Sorting configuration
  const [filter, setFilter] = useState({
    name: "",
    role: "",
    department: "",
    status: ""
  });
  const [loading, setLoading] = useState(false); // Loading state for spinner

  const organizationId = localStorage.getItem("orgId"); // Replace with the actual organization ID (you can make it dynamic if needed)

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  // Fetch users from API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${baseurl}/users/organization/${organizationId}`);
        setUsers(response.data); // Set the user data from the response
        setFilteredUsers(response.data); // Initialize filteredUsers with the original data
        setError(null); // Clear any previous errors
      } catch (err) {
        console.error("Error fetching users:", err);
        setError("Failed to fetch users. Please try again later.");
      }
    };

    fetchUsers();
  }, [organizationId]);

  const encodeIdToBase64 = (id) => {
    return btoa(id.toString()); // Converts ID to a Base64 string
  };

  // Sorting logic
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedData = [...filteredUsers].sort((a, b) => {
      if (a[key] === null || a[key] === undefined) return 1;
      if (b[key] === null || b[key] === undefined) return -1;

      if (direction === "asc") {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });

    setFilteredUsers(sortedData);
    setSortConfig({ key, direction });
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? "▲" : "▼";
    }
    return null;
  };

  // Filter logic
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevState) => {
      const updatedFilter = { ...prevState, [name]: value };
      return updatedFilter;
    });
  };

  const applyFilter = (filter) => {
    setLoading(true); // Show the loading spinner
    setTimeout(() => {
      let filteredData = [...users];
  
      if (filter.name) {
        filteredData = filteredData.filter(user =>
          `${user.first_name} ${user.last_name}`.toLowerCase().includes(filter.name.toLowerCase())
        );
      }
      if (filter.role) {
        filteredData = filteredData.filter(user => user.role_id.toString() === filter.role);
      }
      if (filter.department) {
        filteredData = filteredData.filter(user => user.department.toLowerCase().includes(filter.department.toLowerCase()));
      }
      if (filter.status) {
        filteredData = filteredData.filter(user => (filter.status === "true" ? user.is_active : !user.is_active));
      }
  
      setFilteredUsers(filteredData);
      setLoading(false); // Hide the loading spinner
    }, 500); // Wait for half a second (500ms) before showing the filtered data
  };

  return (
    <div>
       <Topbar/>

      <div className="container mt-4">
        {/* Accordion Section */}
        <div className="row mb-4">
          <div className="crd">
            <div
              className="acc-label"
              onClick={toggleAccordion}
              style={{ cursor: "pointer" }}
            >
              <div><Filter className="ic1" /> System Users</div> <span>{isOpen ? "▲" : "▼"}</span>
            </div>
            <div className={`acc-body ${isOpen ? "open" : "collapsed"}`}>
              <hr />
              <div>
                <form>
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="branch_name" className="w-100">
                        <input
                          type="text"
                          id="branch_name"
                          name="name"
                          value={filter.name}
                          onChange={handleFilterChange}
                          placeholder="User Name"
                        />
                        <span>User Name</span>
                      </label>
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="role" className="w-100">
                      <select
  id="role"
  name="role"
  value={filter.role}
  onChange={handleFilterChange}
>
  <option value="">Select User Role</option>
  <option value="1">HR Manager</option>
  <option value="2">Admin</option>
  <option value="3">Executive / Employee</option>
  <option value="4">Director</option>
</select>

                      </label>
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="department" className="w-100">
                      <select
                          id="department"
                          name="department"
                          value={filter.department}
                          onChange={handleFilterChange}
                        >
                          <option value="">Select Department</option>
                          <option value="Human Resources (HR)">Human Resources (HR)</option>
                          <option value="Finance">Finance</option>
                          <option value="Sales and Marketing">Sales and Marketing</option>
                          <option value="Operations">Operations</option>
                          <option value="Information Technology (IT)">Information Technology (IT)</option>
                          <option value="Legal">Legal</option>
                          <option value="Customer Service">Customer Service</option>
                          <option value="Research and Development (R&D)">Research and Development (R&D)</option>
                          <option value="Product Management">Product Management</option>
                          <option value="Engineering">Engineering</option>
                          <option value="Procurement">Procurement</option>
                          <option value="Administration">Administration</option>
                          <option value="Business Development">Business Development</option>
                          <option value="Corporate Communications">Corporate Communications</option>
                          <option value="Compliance and Risk Management">Compliance and Risk Management</option>
                          <option value="Training and Development">Training and Development</option>
                          <option value="Facilities Management">Facilities Management</option>
                          <option value="Health and Safety">Health and Safety</option>
                          <option value="Creative/Design">Creative/Design</option>
                          <option value="Public Relations">Public Relations</option>
                          <option value="Strategy and Planning">Strategy and Planning</option>
                          <option value="Executive/Management">Executive/Management</option>
                          <option value="Sustainability">Sustainability</option>
                          <option value="Investor Relations">Investor Relations</option>
                          <option value="Mergers and Acquisitions (M&A)">Mergers and Acquisitions (M&A)</option>
                           
                          {/* Add more department options as needed */}
                        </select>
                        {/* <span>Department</span> */}
                      </label>
                    </div>

                    <div className="col-md-3">
                      <label htmlFor="status" className="w-100">
                        <select
                          id="status"
                          name="status"
                          value={filter.status}
                          onChange={handleFilterChange}
                        >
                          <option value="">Select Status</option>
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                      </label>
                    </div>

                    <div className="text-end mt-0">
                      <button type="button" className="btnn" onClick={() => applyFilter(filter)}>
                        Apply Filter
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Users List Section */}
        <div className="row">
          <div className="crd">
            <div className="hd-1">System Users List</div>

            <hr />

            {/* Table Headers */}
            <div className="row text-start tblrowhd">
              <div className="col" onClick={() => handleSort("first_name")} style={{ cursor: "pointer" }}>
                Name {getArrow("first_name")}
              </div>
              <div className="col" onClick={() => handleSort("email")} style={{ cursor: "pointer" }}>
                Email {getArrow("email")}
              </div>
              <div className="col" onClick={() => handleSort("department")} style={{ cursor: "pointer" }}>
                Department {getArrow("department")}
              </div>
              <div className="col" onClick={() => handleSort("designation")} style={{ cursor: "pointer" }}>
                Designation {getArrow("designation")}
              </div>
              <div className="col" onClick={() => handleSort("is_active")} style={{ cursor: "pointer" }}>
                User Role {getArrow("is_active")}
              </div>

              <div className="col">
                Status
              </div>

              <div className="col text-end" >
                Action
              </div>
            </div>
            <hr />

            {/* Spinner Loader */}
            {loading && (
              <div className="text-center mt-4">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}

            {/* User Data Rows */}
            {!loading && (
  error ? (
    // Show error message if there is an error
    <div>{error}</div>
  ) : filteredUsers.length === 0 ? (
    // Show "No records found" if no users match the filter
    <div className="nodata align-items-center d-flex justify-content-center">No records found</div>
  ) : (
    // Display the filtered users
    filteredUsers.map((user, index) => (
      <div key={index} className="row user-table-data tblrowtd text-start">
        <div className="col">{user.first_name} {user.last_name}</div>
        <div className="col">{user.email}</div>
        <div className="col">{user.department}</div>
        <div className="col">{user.designation}</div>
        <div className="col">
          {user.role_id === 1 ? "HR Manager" : 
           user.role_id === 2 ? "Admin" : 
           user.role_id === 3 ? "Executive / Employee" : 
           user.role_id === 4 ? "Director" : "Unknown Role"}
        </div>
        <div className="col"><span className="statusbg">{user.is_active === true ? "Active" : "Inactive"}</span></div>
        <div className="col text-end">
            <Link to={`/edit-user/${encodeIdToBase64(user.id)}`} className="editbg"><Edit className="ic1" /></Link>
             
            </div>
      </div>
    ))
  )
)}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
