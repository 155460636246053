import React, { useEffect, useState } from "react";

const PieChart = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = "https://code.jscharting.com/latest/jscharting.js";
      script.onload = () => setIsLoaded(true);
      script.onerror = () => console.error("Failed to load JSC script");
      document.head.appendChild(script);
    };

    if (!window.JSC) {
      loadScript();
    } else {
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isLoaded && window.JSC) {
      window.JSC.chart("donut-chart-container", {
        type: "donut",
        title_label_text: "Sales Distribution (2024)",
        title_label_style_fontSize: "14px",
        title_label_style_fontWeight: "600",
        title_label_style_color: "#333", // Dark color for a professional look
        legend_visible: true,
        legend_position: "right", // Moving the legend to the right
        donut_inner_radius: "50%", // This turns the pie chart into a donut chart
        series: [
          {
            points: [
              { name: "Electronics", y: 30, color: "#4CAF50" },
              { name: "Fashion", y: 20, color: "#FFC107" },
              { name: "Home & Kitchen", y: 25, color: "#2196F3" },
              { name: "Books", y: 15, color: "#FF5722" },
              { name: "Others", y: 10, color: "#9C27B0" },
            ],
            border_width: 2, // Adding borders to each slice for better visibility
            border_color: "#ffffff", // White border for contrast
            label_text: "%name (%percent)", // Display percentage and name
            label_style_fontSize: "14px",
            label_style_fontWeight: "bold",
            label_style_color: "#fff", // White text for labels for contrast
            tooltip: {
              enabled: true,
              text: "%name: %percent%",
              style_fontSize: "14px",
            },
            animation: {
              duration: 1000, // Smooth animation
            },
          },
        ],
        plot_area_background: "#f8f8f8", // Light background for the chart area
      });
    }
  }, [isLoaded]);

  return <div id="donut-chart-container" style={{ height: "300px", width: "100%" }}></div>;
};

export default PieChart;
