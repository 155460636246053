import React from "react";
import { Heart} from "react-feather";

const FooterOne = () => {
  return (
    <footer className="footer1">
      <div className="footer-content">
      <hr />

        <p className="mt-2 text-start">Developed with <Heart className="ic" /> All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default FooterOne;
