import React, { useEffect, useState } from "react";

const LineChart = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = "https://code.jscharting.com/latest/jscharting.js";
      script.onload = () => setIsLoaded(true);
      script.onerror = () => console.error("Failed to load JSC script");
      document.head.appendChild(script);
    };

    if (!window.JSC) {
      loadScript();
    } else {
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isLoaded && window.JSC) {
      window.JSC.chart("line-chart-container", {
        type: "line",
        title_label_text: "Visitors Over Time (Line Chart)",
        xAxis: {
          label_text: "Time (Days)",
          scale_interval: 1,
        },
        yAxis: {
          label_text: "Number of Visitors",
        },
        series: [
          {
            name: "Visitors",
            points: [
              { x: 1, y: 50 },
              { x: 2, y: 75 },
              { x: 3, y: 30 },
              { x: 4, y: 90 },
              { x: 5, y: 65 },
              { x: 6, y: 120 },
              { x: 7, y: 85 },
            ],
          },
        ],
      });
    }
  }, [isLoaded]);

  return <div id="line-chart-container" style={{ height: "300px", width: "100%" }}></div>;
};

export default LineChart;
