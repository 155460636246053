import React, { useState, useEffect } from "react";
import axios from "axios"; // For API calls
import { Link, useNavigate } from "react-router-dom";
import { baseurl } from "../component/Baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

const CreateUser = () => {
  const [countryCodes, setCountryCodes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [branches, setBranches] = useState([]); // Initialized as an empty array
  const [designations, setDesignations] = useState([]); 
  const [formData, setFormData] = useState({
    first_name: "", // Fixed field
    last_name: "",
    country_code: "",
    phone: "",
    role: "",
    selectedBranch: "",
    designation: "",
    department: "",
    email: "",
    password: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    email: "",
  });

  const navigate = useNavigate();

  // Fetch country codes and countries
  useEffect(() => {
    setCountryCodes([
      // { code: "+1", label: "USA" },
      // { code: "+44", label: "UK" },
      { code: "+91", label: "India" },
    ]);

    setCountries([
      { code: "IN", name: "India" },
      { code: "US", name: "United States" },
      { code: "GB", name: "United Kingdom" },
    ]);
  }, []);

  // Fetch branches for a given organization
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const orgId = localStorage.getItem("orgId"); // You may fetch the orgId dynamically
        const response = await axios.get(`${baseurl}branches/${orgId}`);
        console.log("API Response:", response); // Debug the API response

        if (Array.isArray(response.data.branches)) {
          setBranches(response.data.branches); // Set branches if it's an array
        } else {
          toast.error("Branches data is not an array.");
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
        toast.error("Error fetching branches.");
      }
    };

    fetchBranches();
  }, []);


  // Fetch designations for the organization
  useEffect(() => {
    const fetchDesignations = async () => {
      try {
        const response = await axios.get(`${baseurl}getAllDesignations`);
        console.log("API Response for Designations:", response);

        if (Array.isArray(response.data)) {
          setDesignations(response.data); // Set designations if it's an array
        } else {
          toast.error("Designations data is not an array.");
        }
      } catch (error) {
        console.error("Error fetching designations:", error);
        toast.error("Error fetching designations.");
      }
    };

    fetchDesignations();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset the error messages
    setErrorMessages({});

    // Example of basic form validation
    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.email ||
      !formData.password
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    try {
      // Add the organization_id to the formData
      const orgId = localStorage.getItem("orgId");
      const dataToSend = { ...formData, organization_id: orgId };
      console.log("Form Data sent to API:", dataToSend);

      // Make the API call to create the user with the organization_id
      const response = await axios.post(`${baseurl}createUser`, dataToSend);

      // Display SweetAlert2 modal on success
      Swal.fire({
        icon: "success",
        title: "User Created Successfully",
        text: response.data.message,
        confirmButtonText: "OK",
      }).then(() => {
        // Redirect to user list or dashboard after confirmation
        navigate("/user-list");
      });
    } catch (error) {
      console.error("Error creating user:", error);

      // Check if the error has a response and status
      if (error.response) {
        const status = error.response.status;

        // Handle specific status codes
        if (status === 409) {
          // Both email and phone already exist
          toast.error(
            "Both email and phone number already exist. Please use different ones."
          );
          setErrorMessages({
            email:
              "Email already exists. Please use a different email address.",
            phone:
              "Phone number already exists. Please use a different phone number.",
          });
        } else if (status === 410) {
          // Email already exists
          toast.error(
            "Email already exists. Please use a different email address."
          );
          setErrorMessages({
            email:
              "Email already exists. Please use a different email address.",
          });
        } else if (status === 411) {
          // Phone already exists
          toast.error(
            "Phone number already exists. Please use a different phone number."
          );
          setErrorMessages({
            phone:
              "Phone number already exists. Please use a different phone number.",
          });
        } else {
          toast.error("Error creating user.");
        }
      } else {
        toast.error("Error creating user.");
      }
    }
  };

  return (
    <section className="bg-signup">
      <div className="tp-hdr sticky-top mb-4 d-flex align-items-center px-4 justify-content-between">
        <span className="tptxt">Create User</span>
        <span>
          <Link to="/dashboard" className="btnnSkip d-flex align-items-center">
            Dashboard
          </Link>
        </span>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="my-card p-5">
              <div>
                <h3 className="header-title">Create New User</h3>

                <div className="register mt-3">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="first_name" className="w-100">
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            placeholder="First Name"
                            required
                          />
                          <span>First Name</span>
                        </label>
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="last_name" className="w-100">
                          <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            placeholder="Last Name"
                            required
                          />
                          <span>Last Name</span>
                        </label>
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="country_code" className="w-100">
                          <select
                            id="country_code"
                            name="country_code"
                            value={formData.country_code}
                            onChange={handleChange}
                            required
                          >
                            {/* <option value="">Select Country Code</option> */}
                            {countryCodes.map((code) => (
                              <option key={code.code} value={code.code}>
                                {code.label} ({code.code})
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>

                      <div className="col-md-9">
                        <label htmlFor="phone" className="w-100">
                          <input
                            type="text"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Phone"
                            required
                          />
                          <span>Phone</span>
                        </label>
                        {errorMessages.phone && (
                          <div className="error-message errormsg">
                            {errorMessages.phone}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="email" className="w-100">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Email Address"
                            required
                          />
                          <span>Email Address</span>
                        </label>
                        {errorMessages.email && (
                          <div className="error-message errormsg">
                            {errorMessages.email}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="password" className="w-100">
                          <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Account Password"
                            required
                          />
                          <span>Account Password</span>
                        </label>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="role" className="w-100">
                          <select
                            id="role"
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            required
                          >
                            <option value="" disabled>Select Role</option>
                            <option value="1">HR Manager</option>
                            <option value="2">Admin</option>
                            <option value="3">Executive / Employee</option>
                            <option value="4">Director</option>
                          </select>
                        </label>
                        <div className="smalla text-muted">Rules are defined below </div>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="selectedBranch" className="w-100">
                          <select
                            id="selectedBranch"
                            name="selectedBranch"
                            value={formData.selectedBranch}
                            onChange={handleChange}
                            required
                          >
                            <option value="" disabled>Select Branch</option>
                            {Array.isArray(branches) &&
                              branches.map((branch) => (
                                <option key={branch.id} value={branch.id}>
                                  {branch.branch_name} [{branch.branch_city},
                                  {branch.branch_state}]
                                </option>
                              ))}
                          </select>
                        </label>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="designation" className="w-100">
                        <select
        id="designation"
        name="designation"
        value={formData.designation}
        onChange={handleChange}
        required
      >
        <option value="" disabled>Select Designation</option>
        {designations.map((designation) => (
          <option key={designation.designation} value={designation.designation}>
            {designation.designation} {/* Adjust based on your API response */}
          </option>
        ))}
      </select>
                        </label>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="department" className="w-100">
                          <select
                            id="department"
                            name="department"
                            value={formData.department}
                            onChange={handleChange}
                            required
                          >
                            <option value="" disabled>Select Department</option> 
                          <option value="Human Resources (HR)">Human Resources (HR)</option>
                          <option value="Finance">Finance</option>
                          <option value="Sales and Marketing">Sales and Marketing</option>
                          <option value="Operations">Operations</option>
                          <option value="Information Technology (IT)">Information Technology (IT)</option>
                          <option value="Legal">Legal</option>
                          <option value="Customer Service">Customer Service</option>
                          <option value="Research and Development (R&D)">Research and Development (R&D)</option>
                          <option value="Product Management">Product Management</option>
                          <option value="Engineering">Engineering</option>
                          <option value="Procurement">Procurement</option>
                          <option value="Administration">Administration</option>
                          <option value="Business Development">Business Development</option>
                          <option value="Corporate Communications">Corporate Communications</option>
                          <option value="Compliance and Risk Management">Compliance and Risk Management</option>
                          <option value="Training and Development">Training and Development</option>
                          <option value="Facilities Management">Facilities Management</option>
                          <option value="Health and Safety">Health and Safety</option>
                          <option value="Creative/Design">Creative/Design</option>
                          <option value="Public Relations">Public Relations</option>
                          <option value="Strategy and Planning">Strategy and Planning</option>
                          <option value="Executive/Management">Executive/Management</option>
                          <option value="Sustainability">Sustainability</option>
                          <option value="Investor Relations">Investor Relations</option>
                          <option value="Mergers and Acquisitions (M&A)">Mergers and Acquisitions (M&A)</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <hr />
                    <p className="text-muted ptxt">
                      <strong>Role's Access</strong>
                    </p>
                    <ul>
                        <li className="text-muted ptxt"><strong>HR Manager Role: </strong>Has access to Leave, Recruitment, and Performance modules to manage employee workflows.</li>
                        <li className="text-muted ptxt"><strong>HR Manager Role: </strong>Has access to Leave, Recruitment, and Performance modules to manage employee workflows.</li>
                        <li className="text-muted ptxt"><strong>HR Manager Role: </strong>Has access to Leave, Recruitment, and Performance modules to manage employee workflows.</li>
                        <li className="text-muted ptxt"><strong>HR Manager Role: </strong>Has access to Leave, Recruitment, and Performance modules to manage employee workflows.</li>
                    </ul>

                    <div className="text-end mt-3">
                      <button type="submit" className="btnn">
                        Create User
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </section>
  );
};

export default CreateUser;
