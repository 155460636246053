import React, { useState } from "react";
import { useExhibition } from "../../context/ExhibitionContext"; // Import the context hook
import { useNavigate } from 'react-router-dom'; // Import navigate hook
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2'; // Import SweetAlert2 for the confirmation dialog
import { baseurl, imageUrl } from "../../component/Baseurl";

const AddExhibitor = () => {
  const { exhibitionId } = useExhibition(); // Assuming org_id is also available in context
  const navigate = useNavigate(); // For navigation after submission

  // State for form fields
  const [exhibitorDetails, setExhibitorDetails] = useState({
    exhibitorName: "",
    designation: "",
    company: "",
    email: "",
    phone: "",
    boothNo: "",
    profileImage: null, // To store the selected profile image
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExhibitorDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Handle file input (Profile Image)
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setExhibitorDetails((prevDetails) => ({
      ...prevDetails,
      profileImage: file,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append("exhibitorName", exhibitorDetails.exhibitorName);
    formData.append("designation", exhibitorDetails.designation);
    formData.append("company", exhibitorDetails.company);
    formData.append("email", exhibitorDetails.email);
    formData.append("phone", exhibitorDetails.phone);
    formData.append("boothNo", exhibitorDetails.boothNo);
    if (exhibitorDetails.profileImage) {
      formData.append("profileImage", exhibitorDetails.profileImage);
    }
    formData.append("exhibitionId", exhibitionId);
  
    // Log the data to be sent
    console.log("FormData entries:");
    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
  
    try {
      const response = await fetch(`${baseurl}/insertExhibitorDetails`, {
        method: "POST",
        body: formData,
      });
  
      // Check if the response is OK and returns a JSON response
      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          Swal.fire({
            title: "Success",
            text: "The exhibitor has been added successfully.",
            icon: "success",
          });
          toast.success("Exhibitor added successfully!");
  
          // Reset the form
          setExhibitorDetails({
            exhibitorName: "",
            designation: "",
            company: "",
            email: "",
            phone: "",
            boothNo: "",
            profileImage: null,
          });
  
          // Navigate to another page if necessary
          // navigate("/some-route");
        } else {
          toast.error(`Failed to add exhibitor: ${data.error || 'Unknown error'}`);
        }
      } else {
        // Handle server error response
        const error = await response.text();
        toast.error(`Failed to add exhibitor: ${error}`);
      }
    } catch (error) {
      console.error("Error adding exhibitor:", error);
      toast.error("Something went wrong. Please try again later.");
    }
  
    // Close the modal after form submission
    window.bootstrap.Modal.getInstance(document.getElementById('addExhibitorModal')).hide();
  };
  
  
  
  

  // Preview profile image
  const renderImagePreview = () => {
    if (exhibitorDetails.profileImage) {
      return (
        <div className="mt-3">
          <img
            src={URL.createObjectURL(exhibitorDetails.profileImage)}
            alt="Profile Preview"
            className="img-fluid"
            style={{ maxWidth: '150px' }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {/* Button to open the modal */}
      <div className="d-flex justify-content-between">
      <span><h1 className="mt-3">Exhibitor List</h1></span>
<button
          type="button"
          className="btnn"
          data-bs-toggle="modal"
          data-bs-target="#addExhibitorModal"
        >
          Add Exhibitor
        </button>


        
      </div>

      {/* Modal to add Exhibitor */}
      <div
        className="modal fade"
        id="addExhibitorModal"
        tabIndex="-1"
        aria-labelledby="addExhibitorModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addExhibitorModalLabel">Add Exhibitor</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="exhibitorName">Exhibitor Name<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="exhibitorName"
                    className="form-control"
                    value={exhibitorDetails.exhibitorName}
                    onChange={handleInputChange}
                    placeholder="Enter Exhibitor Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="designation">Designation<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="designation"
                    className="form-control"
                    value={exhibitorDetails.designation}
                    onChange={handleInputChange}
                    placeholder="Enter Designation"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="company">Company<span className="text-danger">*</span></label>
                  <input
                    type="text"
                    name="company"
                    className="form-control"
                    value={exhibitorDetails.company}
                    onChange={handleInputChange}
                    placeholder="Enter Company Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email<span className="text-danger">*</span></label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={exhibitorDetails.email}
                    onChange={handleInputChange}
                    placeholder="Enter Email Address"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    className="form-control"
                    value={exhibitorDetails.phone}
                    onChange={handleInputChange}
                    placeholder="Enter Phone Number"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="boothNo">Booth No</label>
                  <input
                    type="text"
                    name="boothNo"
                    className="form-control"
                    value={exhibitorDetails.boothNo}
                    onChange={handleInputChange}
                    placeholder="Enter Booth No"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="profileImage">Profile Image</label>
                  <input
                    type="file"
                    name="profileImage"
                    className="form-control"
                    onChange={handleFileChange}
                  />
                  {renderImagePreview()} {/* Display profile image preview */}
                </div>
                <div className="modal-footer">
                  {/* <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button> */}
                  <button type="submit" className="btnn">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default AddExhibitor;
