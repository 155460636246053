import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap"; // Import Bootstrap components
import { Edit, Trash2, PlusCircle } from "react-feather"; // Icons for actions
import ExhibitionLeadsNavbar from "../utils/ExhibitionLeadsNavbar";
import { baseurl } from "../Baseurl";
import axios from "axios";
// import { useExhibition } from "../../context/ExhibitionContext";

const CustomForm = ({ eventId, formFor }) => {
  const [fields, setFields] = useState([]); // Store dynamic form fields
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [currentField, setCurrentField] = useState({}); // Field being edited
  const [isEditing, setIsEditing] = useState(false); // Editing mode flag
  const [options, setOptions] = useState([]); // Options for radio/checkbox
  const [formData, setFormData] = useState({
    formName: "", // Add formName here
  });

  console.log("exhibitionId", eventId);
  console.log("formFor", formFor);

  // Open the modal
  const handleShowModal = () => {
    setCurrentField({});
    setOptions([]);
    setIsEditing(false);
    setShowModal(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setOptions([]);
  };

  // Handle field addition or update
  const handleSaveField = () => {
    const fieldToSave = {
      ...currentField,
      options:
        currentField.type === "radio" || currentField.type === "checkbox"
          ? options
          : [],
    };
    if (isEditing) {
      setFields((prev) =>
        prev.map((field, index) =>
          index === currentField.index ? fieldToSave : field
        )
      );
    } else {
      setFields([...fields, fieldToSave]);
    }
    handleCloseModal();
  };

  // Handle field deletion
  const handleDeleteField = (index) => {
    setFields((prev) => prev.filter((_, i) => i !== index));
  };

  // Handle field edit
  const handleEditField = (field, index) => {
    setCurrentField({ ...field, index });
    setOptions(field.options || []);
    setIsEditing(true);
    setShowModal(true);
  };

  // Handle input changes in the modal
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentField((prev) => ({ ...prev, [name]: value }));
  };

  // Handle adding options for radio/checkbox
  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  // Handle updating options
  const handleOptionChange = (index, value) => {
    setOptions((prev) => prev.map((opt, i) => (i === index ? value : opt)));
  };

  // Handle removing options
  const handleRemoveOption = (index) => {
    setOptions((prev) => prev.filter((_, i) => i !== index));
  };

  // Save form data to the database
  const handleSubmitForm = async () => {
    console.log(fields); // Log fields to check the data
    try {
      const response = await axios.post(`${baseurl}createcustomform`, {
        fields,
        eventId: eventId,
        formName: formData.formName,
        formFor: formFor,
      });

      if (response.status === 200) {
        // alert("Form saved successfully!");
        window.location.reload();
      } else {
        alert("Failed to save form.");
      }
    } catch (error) {
      console.error("Error saving form:", error);
      alert("An error occurred while saving the form.");
    }
  };

  return (
    <div>
      <div className="container mt-4 mb-2">
        <div className="d-flex justify-content-between">
          <h1>Create Custom Form For {formFor}</h1> 
        </div>

        {/* Dynamic Form Preview */}
        <div className=" "> 
          <div className="row">
            <div className="crd1 p-3">
              <div className="row g-2">
                {/* Form Name Input */}
                <div className="col-md-8 border p-2">
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label className="form-label">
                        Form Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="formName"
                        className="form-control"
                        placeholder="Enter Form Name"
                        value={formData.formName}
                        onChange={(e) =>
                          setFormData({ ...formData, formName: e.target.value })
                        }
                        required
                      />
                    </div>

                     <div className="row my-3 justify-content-center">
                        <div className="col-12 mb-2"><hr/></div>
                        <div className="text-center ">
                          <span className="text-danger"><strong>*Default Inputs*</strong></span>
                        </div>
                     </div>


                     <div className="row">
                  <div className="col-md-6">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="formName"
                      className="form-control"
                      placeholder="Enter Name Here"
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">
                      Mobile No. <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="formName"
                      className="form-control"
                      placeholder="Enter Mobile No. Here"
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="formName"
                      className="form-control"
                      placeholder="Enter Email Here"
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">
                      Designation <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="formName"
                      className="form-control" 
                      placeholder="Enter Designation Here"
                      required
                    />
                  </div>
                  </div>

                    <div className="row my-3 justify-content-center">
                        <div className="col-12 mb-2"><hr/></div>
                        <div className="text-center ">
                          <span className="text-danger"><strong>*Custom Inputs*</strong></span>
                        </div>
                     </div>



                    {/* Display Added Fields */}
                    {fields.length > 0 ? (
                      fields.map((field, index) => (
                        <div key={index} className="col-md-12 mb-3">
                          <label className="me-2">{field.label}</label>
                          {field.type === "text" && (
                            <input type="text" className="form-control" />
                          )}
                          {field.type === "number" && (
                            <input type="number" className="form-control" />
                          )}
                          {field.type === "textarea" && (
                            <textarea className="form-control"></textarea>
                          )}
                          {field.type === "radio" &&
                            field.options.map((option, i) => (
                              <div key={i}>
                                <input
                                  type="radio"
                                  name={field.label}
                                  className="form-check-input"
                                />{" "}
                                {option}
                              </div>
                            ))}
                          {field.type === "checkbox" &&
                            field.options.map((option, i) => (
                              <div key={i}>
                                <input
                                  type="checkbox"
                                  name={field.label}
                                  className="form-check-input"
                                />{" "}
                                {option}
                              </div>
                            ))}
                          {field.type === "file" && (
                            <input type="file" className="form-control" />
                          )}
                          <div className="d-flex justify-content-end ms-2 text-end mt-2">
                            <div
                              variant="outline-success"
                              className="me-1 editvari text-white"
                              onClick={() => handleEditField(field, index)}
                            >
                              <Edit size={20} /> Edit Input
                            </div>
                            <div
                              variant="outline-danger"
                              className="deletvari text-white"
                              onClick={() => handleDeleteField(index)}
                            >
                              <Trash2 size={20} /> Delete Input
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="mt-4 defaulttext">
                        Create Custom Form using <i>"Add Input Field"</i>{" "}
                        Button.
                      </p>
                    )}

                    {/* Submit Form Button */}
                    {fields.length > 0 && (
                      <div className="mt-3 text-end">
                        <Button variant="success" onClick={handleSubmitForm}>
                          Submit Form
                        </Button>
                      </div>
                    )}
                  </div>
                </div>


<div className="col-md-4">
<div className="text-end ">
<Button
            variant="primary"
            className="mt-0 btnn"
            onClick={handleShowModal}
          >
            <PlusCircle /> Add Input Field
          </Button>
</div>
</div>

              </div>
            </div>
          </div>
        </div>

        {/* Modal for Adding/Editing Input */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? "Edit Input" : "Add Input"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Input Label</Form.Label>
                <Form.Control
                  type="text"
                  name="label"
                  value={currentField.label || ""}
                  onChange={handleInputChange}
                  placeholder="Enter label"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Input Type</Form.Label>
                <Form.Select
                  name="type"
                  value={currentField.type || ""}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Type</option>
                  <option value="text">Text</option>
                  <option value="number">Number</option>
                  <option value="textarea">Textarea</option>
                  <option value="radio">Radio</option>
                  <option value="checkbox">Checkbox</option>
                  <option value="file">File</option>
                </Form.Select>
              </Form.Group>
              {(currentField.type === "radio" ||
                currentField.type === "checkbox") && (
                <Form.Group>
                  <Form.Label>Options</Form.Label>
                  {options.map((option, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                      <Form.Control
                        type="text"
                        value={option}
                        onChange={(e) =>
                          handleOptionChange(index, e.target.value)
                        }
                        placeholder={`Option ${index + 1}`}
                        className="me-2"
                      />
                      <Button
                        variant="outline-danger mt-0"
                        onClick={() => handleRemoveOption(index)}
                      >
                        <Trash2 size={20} />
                      </Button>
                    </div>
                  ))}
                  <Button variant="outline-primary" onClick={handleAddOption}>
                    Add Option
                  </Button>
                </Form.Group>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button> */}
            <Button
              variant="primary"
              className="btnn"
              onClick={handleSaveField}
            >
              {isEditing ? "Update Input" : "Add Input"}
               
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default CustomForm;
