import React from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { Search, LogOut as UsersIcon } from "react-feather"; 
import { useExhibition } from "../../context/ExhibitionContext"; 

const ExhibitionReportNavbar = () => {
    const { exhibitionId } = useExhibition();
    const location = useLocation(); // Get the current location

    // Function to check if the current path is the same as the given path
    const isActive = (path) => location.pathname === path;

    return (
        <div className="fixedtp30 mt-3">
            {/* Search Box */}
            <div className="chaintop">
                <span>
                    <Link 
                        to={`/exhibition-dashboard/${exhibitionId}/web-analytics`} 
                        className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/web-analytics`) ? "active1" : ""}`}
                    >
                        Analytics Report
                    </Link>
                </span>
                <span>
                    <Link 
                        to={`/exhibition-dashboard/${exhibitionId}/geo-analytics`} 
                        className={`spanli ${isActive(`/exhibition-dashboard/${exhibitionId}/geo-analytics`) ? "active1" : ""}`}
                    >
                        Geography  Report
                    </Link>
                </span>
                 
                 
            </div>
        </div>
    );
};

export default ExhibitionReportNavbar;
