import React, { useState } from "react";

const Logout = () => {
    try {
      // Clear all data from localStorage
      localStorage.clear();
  
      // Optionally redirect to the login page or another page
      window.location.href = "/"; // Adjust the path based on your app's routing
      console.log("User logged out successfully, localStorage cleared.");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };
  
  
  export default Logout;
  