import React from "react";
import { Heart} from "react-feather";

const FooterTwo = () => {
  return (
    <footer className="footer1">
      <div className="footer-content">
    

        <p className="mt-2 text-end px-3">Developed with <Heart className="ic" /> All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default FooterTwo;
