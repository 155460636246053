// src/pages/ConferenceDashboard.js
import React, { useEffect } from 'react';
import { useParams, Link, Outlet } from 'react-router-dom'; 
import { useConference } from '../context/ConferenceContext'; // Import the context for conferences

// Import the components
import ConferenceSidebar from '../component/sidebar/ConferenceSidebar'; // Sidebar for conference
import Topbar from '../component/utils/Topbar';

const ConferenceDashboard = () => {
  const { conference_unique_id } = useParams(); // Get the conference ID from the URL
  const { setConferenceId } = useConference(); // Access the setConferenceId function from the context

  // Update the conferenceId in context when the component mounts or URL changes
  useEffect(() => {
    if (conference_unique_id) {
      setConferenceId(conference_unique_id); // Set the conferenceId in the context
    }
  }, [conference_unique_id, setConferenceId]);
  
  return (
    <div>
        <ConferenceSidebar /> 

        <div className="content" style={{ flexGrow: 1, padding: "20px" }}>

          <Topbar /> {/* Topbar for the conference dashboard */}
          <Outlet /> {/* Render nested routes for the conference dashboard */}
        
        </div>
    </div>
  );
};

export default ConferenceDashboard;
