import React, { createContext, useContext, useState } from 'react';

// Create the context
const ExhibitionContext = createContext();

// Create a custom hook to use the context
export const useExhibition = () => {
  return useContext(ExhibitionContext);
};

// Create the provider component
export const ExhibitionProvider = ({ children }) => {
  const [exhibitionId, setExhibitionId] = useState(null);

  return (
    <ExhibitionContext.Provider value={{ exhibitionId, setExhibitionId }}>
      {children}
    </ExhibitionContext.Provider>
  );
};
