import React, { useState, useEffect } from "react";
import { useExhibition } from "../../context/ExhibitionContext";  
import ReactDatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";  
import axios from "axios"; 
import { baseurl } from "../../component/Baseurl";  
import { useNavigate } from 'react-router-dom';  
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import ExhibitionContentNavbar from "../utils/ExhibitionContentNavbar";
import { ArrowRight } from "react-feather";

const ExhibitionBasicDetails = () => {
  
  const { exhibitionId } = useExhibition(); 

  const navigate = useNavigate(); 

 
  const [formData, setFormData] = useState({
    exhibitionName: "",
    organisedBy: "",
    frequency: "",
  });

  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const org_id = localStorage.getItem("orgId");
const [activeToggle, setActiveToggle] = useState(null);
  
 
  useEffect(() => {
    if (!org_id || !exhibitionId) {
      toast.error("orgId or exhibitionId is missing!");
      return;
    }

    const fetchExhibitionData = async () => {
      try {
        console.log("Sending request with org_id:", org_id, "and exhibitionId:", exhibitionId);

        const response = await axios.get(`${baseurl}exhibitionBasicDetails/${org_id}/${exhibitionId}`);

        console.log("Fetched Exhibition Data:", response.data);

        const { exhibition, industries, venue } = response.data;

        setFormData({
          exhibitionName: exhibition.exhibition_name,
          organisedBy: exhibition.organised_by,
          frequency: exhibition.exhibition_frequency,
          venueName: venue.venue_name,
            venueAddress: venue.venue_address,
            venueMap: venue.venue_map,
            timezone: venue.timezone || '',
            joiningLink: venue.joining_link || '',
            eventMode: venue.event_mode || ''
        });
        setActiveToggle(venue.event_mode);

        setSelectedIndustries(industries.map((industry) => industry.industry));
        setStartDate(new Date(exhibition.exhibition_start_date));
        setEndDate(new Date(exhibition.exhibition_end_date));
      } catch (error) {
        toast.error("Error fetching exhibition details!");
        console.error(error);
      }
    };

    fetchExhibitionData();
  }, [exhibitionId]);
 

  // Handle toggle change
  const handleToggleChange = (toggle) => {
    setActiveToggle(activeToggle === toggle ? null : toggle); // Toggle on/off
    setFormData({ ...formData, eventMode: toggle }); // Update eventMode based on the toggle
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue && !selectedIndustries.includes(selectedValue)) {
      setSelectedIndustries((prev) => [...prev, selectedValue]);
    }
  };
  const handleIndustryClick = (industry) => {
    setSelectedIndustries((prev) => prev.filter((item) => item !== industry));
  };
  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      toast.error("End date must be after the start date.");
      setEndDate(null);  
    } else {
      setEndDate(date);
    }
  };
  const handleSubmit = async (e) => {

    const { venueName, venueAddress, venueMap, timezone, joiningLink, eventMode } = formData;
      
        // Validate form data (basic validation)
        if (!venueName || !venueAddress || !venueMap || (activeToggle === 'virtual' || activeToggle === 'hybrid') && (!timezone || !joiningLink)) {
          toast.error("Please fill in all required fields.");
          return;
        }

        
    e.preventDefault();
    console.log("Sending data to update exhibition:", {
      org_id, 
      exhibitionId,
      ...formData,
      selectedIndustries,
      startDate,
      endDate,
      venueName,
        venueAddress,
        venueMap,
        timezone,
        joiningLink,
        eventMode 
    });


    

    try {
      const response = await axios.post(`${baseurl}/update-exhibition`, {
        org_id,
        exhibitionId,
        ...formData,
        selectedIndustries,
        startDate,
        endDate,
        venueName,
        venueAddress,
        venueMap,
        timezone,
        joiningLink,
        eventMode 
      });
  
      if (response.status === 200) { 
        navigate(`/exhibition-dashboard/${exhibitionId}/additional-details`);
      }
    } catch (error) {
      console.error("API Error:", error); 
    }
  };
  
  return (
    <div>
      <ExhibitionContentNavbar/>
      <h1 className="mt-3">Basic Details</h1>
      <div className="row">
        <div className="col-md-12">
          <div className="crd1 p-3">
            <form onSubmit={handleSubmit}>
              <div className="row g-2">
                <div className="col-md-12">
                  <label className="form-label">
                    Enter Exhibition Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="exhibitionName"
                    className="form-control"
                    placeholder="Enter Exhibition Name"
                    value={formData.exhibitionName}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-md-6">
                  <label className="form-label">
                    Exhibition Industry (Select From Dropdown)
                  </label>
                  <textarea
                    className="txtarea"
                    rows="4"
                    placeholder="Select Industry From Dropdown"
                    value={selectedIndustries.join(", ")}
                    readOnly
                  ></textarea>
                </div>

                <div className="col-md-6">
                  <label className="form-label">
                    Select Industry <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    onChange={handleDropdownChange}
                    
                  >
                    <option value="">Select Industry</option>
                    <option value="Science & Technology">Science & Technology</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Education">Education</option>
                    <option value="Finance">Finance</option>
                  </select>
                  <div className="selected-industries mt-2">
                    {selectedIndustries.map((industry, index) => (
                      <span
                        key={index}
                        className="industry-item"
                        onClick={() => handleIndustryClick(industry)}
                        style={{
                          textDecoration: "line-through",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                      >
                        {industry}
                      </span>
                    ))}
                  </div>
                </div>

                <div className="col-md-12">
                  <label className="form-label">
                    Organised By <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="organisedBy"
                    className="form-control"
                    placeholder="Enter Organiser Name"
                    value={formData.organisedBy}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">
                    Exhibition Start Date <span className="text-danger">*</span>
                  </label>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Select Start Date"
                    required
                    minDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">
                    Exhibition End Date <span className="text-danger">*</span>
                  </label>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    placeholderText="Select End Date"
                    required
                    minDate={startDate || new Date()}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </div>

                <div className="col-md-4">
                  <label className="form-label">
                    Exhibition Frequency <span className="text-danger">*</span>
                  </label>
                  <select
                    name="frequency"
                    className="form-control"
                    value={formData.frequency}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Frequency</option>
                    <option value="One Time Event">One Time Event</option>
                    <option value="Every Week">Every Week</option>
                    <option value="Every Month">Every Month</option>
                    <option value="Once In 3 Month">Once In 3 Month</option>
                    <option value="Once In 6 Month">Once In 6 Month</option>
                    <option value="Every Year">Every Year</option>
                  </select>
                </div>



                <div className="col-md-12">
                  <div className="form-check form-switch d-inline-block me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckInPerson"
                      checked={activeToggle === 'inPerson'}
                      onChange={() => handleToggleChange('inPerson')}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckInPerson">InPerson</label>
                  </div>

                  <div className="form-check form-switch d-inline-block me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckVirtual"
                      checked={activeToggle === 'virtual'}
                      onChange={() => handleToggleChange('virtual')}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckVirtual">Virtual</label>
                  </div>

                  <div className="form-check form-switch d-inline-block">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckHybrid"
                      checked={activeToggle === 'hybrid'}
                      onChange={() => handleToggleChange('hybrid')}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckHybrid">Hybrid</label>
                  </div>
                </div>


{/* Conditionally render inputs based on active toggle */}
{activeToggle === 'inPerson' || activeToggle === 'hybrid' ? (
                  <>
                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="venueName"
                        className="form-control"
                        placeholder="Enter Venue Name"
                        value={formData.venueName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Address <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Venue Address"
                        rows="3"
                        name="venueAddress"
                        value={formData.venueAddress}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Venue Map <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Map Iframe Code"
                        rows="3"
                        name="venueMap"
                        value={formData.venueMap}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                  </>
                ) : null}

                {activeToggle === 'virtual' || activeToggle === 'hybrid' ? (
                  <>
                    <div className="col-md-12">
                      <label className="form-label">
                        Select TimeZone <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Timezone"
                        rows="3"
                        name="timezone"
                        value={formData.timezone}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">
                        Joining Link <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Joining Link"
                        rows="3"
                        name="joiningLink"
                        value={formData.joiningLink}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                  </>
                ) : null}




              </div>

              <div className="col-md-12 mt-3 text-end">
                <button type="submit" className="btnn">
                  Next <ArrowRight className="ic text-white" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionBasicDetails;
